"use client";
import { useEffect, useState } from "react";
//redux
import { useDispatch, useSelector } from "react-redux";
//slices
import {
  setCurrentIndex,
  setIndexDetails,
  setIndexLTP,
  setSocketTimeStamp,
} from "../../Redux/Slices/DataSlice";
//socket
import getSocket from "../../Services/API/Socket";
//ui
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../Components/ui/select";
import ApiService from "../../Services/ApiService";
import { useNavigate, useParams } from "react-router";
//error-component
// import { useErrorBoundary } from "react-error-boundary";

function TradeHead({ isLoggedIn }) {
  const [indexKeys, setIndexKeys] = useState([]);
  const [index, setIndex] = useState(null);
  const [indexExpiry, setIndexExpiry] = useState("");
  const [indexLtp, setIndexLtp] = useState("");
  const [lastPrice, setLastPrice] = useState("");
  const [openLTP, setOpenLTP] = useState(0);
  const [isFixed, setIsFixed] = useState(false);
  const [plSummary, setPlSummary] = useState(0);
  const { indexname } = useParams();
  const checkoutList = localStorage.getItem("Buy_Option")
    ? JSON.parse(localStorage.getItem("Buy_Option"))
    : [];
  const socket = getSocket();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const triggerState = useSelector((state) => state.tradeData.triggerState);
  isLoggedIn = isLoggedIn === undefined ? false : isLoggedIn;
  const returns = checkoutList.reduce((acc, item) => {
    if (item.Status === "closed") {
      acc += Number(item.Profit_Loss) || 0;
    }
    return acc;
  }, 0);
  // const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    let openStrikeValue = [];

    const totalOpenProfit = (data, purchasedData) => {
      if (data.type === "Quote Data") {
        if (!Array.isArray(openStrikeValue)) {
          openStrikeValue = [];
        }

        const index = openStrikeValue.findIndex(
          (item) => item.strikePrice === data.SEM_STRIKE_PRICE
        );

        if (purchasedData.Type === data.SEM_OPTION_TYPE) {
          if (index !== -1) {
            openStrikeValue[index] = {
              ...openStrikeValue[index],
              LTP: data.LTP,
            };
          } else {
            openStrikeValue.push({
              strikePrice: data.SEM_STRIKE_PRICE,
              LTP: data.LTP,
              lot: purchasedData?.Lot_Size || 0,
              totalBuyPrice: purchasedData?.Total_Price || 0,
            });
          }
        }

        const totalLTP = openStrikeValue.reduce((total, item) => {
          return total + (item.LTP * item.lot - item.totalBuyPrice);
        }, 0);
        setOpenLTP(totalLTP);
      }
    };

    checkoutList?.map((x) => {
      if (x.Status === "open") {
        socket.on(`OPTIDX_${x.Index}_${x.Strike_Price}`, (data) => {
          totalOpenProfit(data, x);
        });
      } else {
        setOpenLTP(0);
      }
    });
  }, [socket, triggerState]);
  useEffect(() => {
    ApiService.fetchData("/data/fetch/indexes")
      .then((data) => {
        const indexes = data.data.indexes;
        const firstIndex = indexes?.[0];
        if (indexKeys.length === 0) {
          setIndexKeys(indexes.map((key) => key.SEM_TRADING_SYMBOL));
        }
        if (index === null) {
          setIndex(indexname ? indexname : firstIndex.SEM_TRADING_SYMBOL);
          setLastPrice(
            indexname
              ? indexes
                  .filter((key) => key.SEM_TRADING_SYMBOL === indexname)
                  .map((data) => data.LTP)
              : firstIndex.LTP
          );
          dispatch(
            setCurrentIndex(
              indexname ? indexname : firstIndex.SEM_TRADING_SYMBOL
            )
          );
          setIndexExpiry(
            indexname
              ? indexes
                  .filter((key) => key.SEM_TRADING_SYMBOL === indexname)
                  .map((data) => data.NEAREST_EXPIRY)
              : firstIndex.NEAREST_EXPIRY
          );
        } else {
          setLastPrice(
            indexes
              .filter((key) => key.SEM_TRADING_SYMBOL === index)
              .map((data) => data.LTP)
          );
          setIndexExpiry(
            indexes
              .filter((key) => key.SEM_TRADING_SYMBOL === index)
              .map((data) => data.NEAREST_EXPIRY)
          );
        }

        dispatch(setIndexDetails(data.data.indexes));
      })
      .catch((error) => {
        // showBoundary(error);
        console.log(error);
      });
  }, [index]);

  useEffect(() => {
    socket.on(`IDX_${index}`, (data) => {
      if (data.LTP) {
        setIndexLtp(data.LTP);
        dispatch(setIndexLTP(data.LTP));
        dispatch(setSocketTimeStamp(Date.now()));
        const profitLoss = isNaN(data?.close - data?.open)
          ? 0
          : (data?.close || 0) - (data?.open || 0);
        setPlSummary((prevState) => {
          if (profitLoss === 0) {
            return prevState;
          } else {
            return profitLoss;
          }
        });
      }
    });

    return () => {
      socket.off(`IDX_${index}`);
    };
  }, [socket, index, indexLtp]);

  const onHandleClickIndex = (data) => {
    if (!isLoggedIn) {
      navigate(`/option-chain/${data}`);
    } else {
      navigate(`/manage/paper-trading/${data}`);
    }

    dispatch(setCurrentIndex(data));
    setIndex(data);
  };
  return (
    <div
      className="px-5 py-3 border border-teal-400 fixed bg-white w-full z-[11]"
      style={{
        top: isFixed && isLoggedIn ? "0px" : "",
      }}
    >
      <div
        className="flex justify-between"
        style={{
          paddingTop: isFixed && !isLoggedIn ? "5px" : "0px",
          transition: "top 0.7s ease, padding-top 0.7s ease",
        }}
      >
        <div className="flex gap-3 justify-center items-center">
          <div className="text-gray-600 font-bold font-serif">{index}</div>
          <div
            className={`flex flex-col justify-center font-bold px-10 text-white rounded-full w-[150px] ${
              plSummary === 0
                ? "bg-blue-500"
                : plSummary > 0
                ? "bg-green-500"
                : "bg-red-500"
            }`}
          >
            {indexLtp ? indexLtp : lastPrice}
          </div>
        </div>
        {isLoggedIn && (
          <>
            <div className="flex flex-col">
              <div className="text-gray-600 font-bold font-serif">RETURNS</div>
              <div
                className={`${
                  returns > 0
                    ? "text-center text-green-500"
                    : "text-center text-red-500"
                }`}
              >
                {Number(returns).toFixed(2)}
              </div>
            </div>
            <div className="flex flex-col">
              <div className="text-gray-600 font-bold font-serif">
                CURRENT RETURNS
              </div>
              <div
                className={`${
                  +openLTP + +returns > 0
                    ? "text-center text-green-500"
                    : "text-center text-red-500"
                }`}
              >
                {(+openLTP + +returns).toFixed(2)}
              </div>
            </div>
          </>
        )}

        <div className="flex gap-[50px] ">
          <div className="flex justify-center items-center">
            <Select onValueChange={onHandleClickIndex}>
              <SelectTrigger className="w-[180px]">
                <SelectValue defaultValue={index} placeholder={index} />
              </SelectTrigger>

              <SelectContent>
                {indexKeys.map((data) => (
                  <SelectItem
                    key={data}
                    value={data}
                    className="cursor-pointer p-3 hover:bg-sky-100"
                  >
                    {data}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="border px-3 flex justify-center items-center text-sm">
            Expiry:&nbsp;<span>{indexExpiry}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TradeHead;
