import React from "react";
import Card from "../../../Components/Admin/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaChevronRight } from "react-icons/fa6";

export default function AdminDashboard() {
  return (
    <div className="container-fluid px-[0.625rem]">
      <div className="grid grid-cols-1 pb-6">
        <div className="md:flex items-center justify-between px-[2px]">
          <h4 className="text-[18px] font-medium text-gray-800 mb-sm-0 grow dark:text-gray-100 mb-2 md:mb-0">
            Dashboard
          </h4>
          <nav className="flex" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 ltr:md:space-x-3 rtl:md:space-x-0">
              <li className="inline-flex items-center">
                <a
                  href="#"
                  className="inline-flex items-center text-sm text-gray-800 hover:text-gray-900 dark:text-zinc-100 dark:hover:text-white"
                >
                  Dashboard
                </a>
              </li>
              <li>
                <div className="flex items-center rtl:mr-2">
                  <FontAwesomeIcon icon={FaChevronRight} />
                  <a
                    href="#"
                    className="text-sm font-medium text-gray-500 ltr:ml-2 rtl:mr-2 hover:text-gray-900 ltr:md:ml-2 rtl:md:mr-2 dark:text-gray-100 dark:hover:text-white"
                  >
                    Dashboard
                  </a>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-6 gap-y-0 2xl:gap-6 md:grid-cols-2 2xl:grid-cols-4">
        <Card
          title="Positions"
          figure="1210"
          change="10%"
          duration="Since Today Morning"
        />
        <Card
          title="Orders"
          figure="1210"
          change={null}
          duration="Since Today Morning"
        />
      </div>
      <div class="grid grid-cols-1  mb-3 gap-y-0  mt-3">
        <div class="card dark:bg-zinc-800 dark:border-zinc-600">
          <div>
            <div class="border-b border-gray-50 card-body dark:border-zinc-700">
              <div class="flex flex-wrap items-center">
                <h5 class="mr-2 text-gray-800 text-15 dark:text-gray-100">
                  Recent Activity
                </h5>
                <div class="ltr:ml-auto rtl:mr-auto">
                  <select class="form-select form-select-md rounded py-0.5 ltr:pl-3 rtl:pr-3 border-gray-50 bg-gray-50/20 dark:border-zinc-600 dark:text-gray-100 dark:bg-zinc-700">
                    <option value="Today" selected="">
                      Today
                    </option>
                    <option value="Yesterday">Yesterday</option>
                    <option value="Last Week">Last Week</option>
                    <option value="Last Month">Last Month</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="px-3 card-body" data-simplebar="init">
              <div class="simplebar-wrapper">
                <div class="simplebar-height-auto-observer-wrapper">
                  <div class="simplebar-height-auto-observer"></div>
                </div>
                <div class="simplebar-mask">
                  <div class="simplebar-offset">
                    <div class="simplebar-content-wrapper">
                      <div class="simplebar-content">
                        <div class="table w-full">
                          <ul>
                            <li class="relative flex">
                              <div class="relative pb-7 pl-14 grow">
                                <div class="absolute top-0 z-40 left-2">
                                  <div class="w-10 h-10 text-center rounded-full bg-yellow-50 dark:bg-yellow-500/20 ">
                                    <i class="text-2xl leading-relaxed text-yellow-500 bx bx-bitcoin"></i>
                                  </div>
                                </div>

                                <div>
                                  <div class="ml-2 mr-4 overflow-hidden grow lg:w-60">
                                    <h5 class="mb-1 text-sm font-medium text-gray-700 dark:text-gray-100">
                                      24/05/2021, 18:24:56
                                    </h5>
                                    <p class="overflow-hidden text-gray-500 text-13 text-ellipsis whitespace-nowrap dark:text-zinc-100">
                                      0xb77ad0099e21d4fca87fa4ca92dda1a40af9e05d205e53f38bf026196fa2e431
                                    </p>
                                  </div>
                                </div>

                                <div class="after:contant-[] after:absolute after:top-0 after:h-20 after:border-l-2 after:border-dashed after:border-gray-100 after:left-7 after:z-0 dark:after:border-zinc-600"></div>
                              </div>

                              <div class="shrink-0">
                                <div class="w-20 mr-1 shrink-0 text-end">
                                  <h6 class="mb-1 font-medium text-gray-700 dark:text-gray-100">
                                    +0.5 BTC
                                  </h6>
                                  <div class="text-13 dark:text-zinc-100">
                                    $178.53
                                  </div>
                                </div>
                              </div>

                              <div class="shrink-0">
                                <div class="relative dropdown">
                                  <a
                                    href="#!"
                                    class="btn py-2.5 dropdown-toggle border-0 text-xl text-gray-700 dark:text-gray-100"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    id="dropdownMenuButton1"
                                  >
                                    <i class="mdi mdi-dots-vertical"></i>
                                  </a>
                                  <ul
                                    class="absolute top-auto z-50 hidden w-40 py-1.5 text-left list-none bg-white border border-gray-50/50 rounded shadow-lg dropdown-menu ltr:!right-0 ltr:!left-auto rtl:!left-0 rtl:!right-auto dark:bg-zinc-700 dark:border-zinc-600 bg-clip-padding"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Action
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Another action
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Something else here
                                      </a>
                                    </li>
                                    <hr class="border-gray-50 my-1.5 dark:border-zinc-600" />
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Separated link
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>

                            <li class="relative flex">
                              <div class="relative pb-7 pl-14 grow">
                                <div class="absolute top-0 z-40 left-2">
                                  <div class="w-10 h-10 text-center rounded-full bg-violet-50 dark:bg-violet-500/20">
                                    <i class="text-2xl leading-relaxed mdi mdi-ethereum text-violet-500"></i>
                                  </div>
                                </div>

                                <div>
                                  <div class="ml-2 mr-4 overflow-hidden grow lg:w-60">
                                    <h5 class="mb-1 text-sm font-medium text-gray-700 dark:text-gray-100">
                                      24/05/2021, 18:24:56
                                    </h5>
                                    <p class="overflow-hidden text-gray-500 text-13 text-ellipsis whitespace-nowrap dark:text-zinc-100">
                                      0xb77ad0099e21d4fca87fa4ca92dda1a40af9e05d205e53f38bf026196fa2e431
                                    </p>
                                  </div>
                                </div>

                                <div class="after:contant-[] after:absolute after:top-0 after:h-20 after:border-l-2 after:border-dashed after:border-gray-100 after:left-7 after:z-0 dark:after:border-zinc-600"></div>
                              </div>

                              <div class="shrink-0">
                                <div class="w-20 mr-1 shrink-0 text-end">
                                  <h6 class="mb-1 font-medium text-gray-700 dark:text-gray-100">
                                    -20.5 ETH
                                  </h6>
                                  <div class="text-13 dark:text-zinc-100">
                                    $3541.45
                                  </div>
                                </div>
                              </div>

                              <div class="shrink-0">
                                <div class="relative dropdown">
                                  <a
                                    href="#!"
                                    class="btn py-2.5 dropdown-toggle border-0 text-xl text-gray-700 dark:text-gray-100"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    id="dropdownMenuButton1"
                                  >
                                    <i class="mdi mdi-dots-vertical"></i>
                                  </a>
                                  <ul
                                    class="absolute top-auto z-50 hidden w-40 py-1.5 text-left list-none bg-white border border-gray-50/50 rounded shadow-lg dropdown-menu ltr:!right-0 ltr:!left-auto rtl:!left-0 rtl:!right-auto dark:bg-zinc-700 dark:border-zinc-600 bg-clip-padding"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Action
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Another action
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Something else here
                                      </a>
                                    </li>
                                    <hr class="border-gray-50 my-1.5 dark:border-zinc-600" />
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Separated link
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>

                            <li class="relative flex">
                              <div class="relative pb-7 pl-14 grow">
                                <div class="absolute top-0 z-40 left-2">
                                  <div class="w-10 h-10 text-center rounded-full bg-yellow-50 dark:bg-yellow-500/20 ">
                                    <i class="text-2xl leading-relaxed text-yellow-500 bx bx-bitcoin"></i>
                                  </div>
                                </div>

                                <div>
                                  <div class="ml-2 mr-4 overflow-hidden grow lg:w-60">
                                    <h5 class="mb-1 text-sm font-medium text-gray-700 dark:text-gray-100">
                                      24/05/2021, 18:24:56
                                    </h5>
                                    <p class="overflow-hidden text-gray-500 text-13 text-ellipsis whitespace-nowrap dark:text-zinc-100">
                                      0xb77ad0099e21d4fca87fa4ca92dda1a40af9e05d205e53f38bf026196fa2e431
                                    </p>
                                  </div>
                                </div>

                                <div class="after:contant-[] after:absolute after:top-0 after:h-20 after:border-l-2 after:border-dashed after:border-gray-100 after:left-7 after:z-0 dark:after:border-zinc-600"></div>
                              </div>

                              <div class="shrink-0">
                                <div class="w-20 mr-1 shrink-0 text-end">
                                  <h6 class="mb-1 font-medium text-gray-700 dark:text-gray-100">
                                    +0.5 BTC
                                  </h6>
                                  <div class="text-13 dark:text-zinc-100">
                                    $5791.45
                                  </div>
                                </div>
                              </div>

                              <div class="shrink-0">
                                <div class="relative dropdown">
                                  <a
                                    href="#!"
                                    class="btn py-2.5 dropdown-toggle border-0 text-xl text-gray-700 dark:text-gray-100"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    id="dropdownMenuButton1"
                                  >
                                    <i class="mdi mdi-dots-vertical"></i>
                                  </a>
                                  <ul
                                    class="absolute top-auto z-50 hidden w-40 py-1.5 text-left list-none bg-white border border-gray-50/50 rounded shadow-lg dropdown-menu ltr:!right-0 ltr:!left-auto rtl:!left-0 rtl:!right-auto dark:bg-zinc-700 dark:border-zinc-600 bg-clip-padding"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Action
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Another action
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Something else here
                                      </a>
                                    </li>
                                    <hr class="border-gray-50 my-1.5 dark:border-zinc-600" />
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Separated link
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>

                            <li class="relative flex">
                              <div class="relative pb-7 pl-14 grow">
                                <div class="absolute top-0 z-40 left-2">
                                  <div class="w-10 h-10 text-center rounded-full bg-violet-50 dark:bg-violet-500/20">
                                    <i class="text-2xl leading-relaxed mdi mdi-litecoin text-violet-500"></i>
                                  </div>
                                </div>

                                <div>
                                  <div class="ml-2 mr-4 overflow-hidden grow lg:w-60">
                                    <h5 class="mb-1 text-sm font-medium text-gray-700 dark:text-gray-100">
                                      24/05/2021, 18:24:56
                                    </h5>
                                    <p class="overflow-hidden text-gray-500 text-13 text-ellipsis whitespace-nowrap dark:text-zinc-100">
                                      0xb77ad0099e21d4fca87fa4ca92dda1a40af9e05d205e53f38bf026196fa2e431
                                    </p>
                                  </div>
                                </div>

                                <div class="after:contant-[] after:absolute after:top-0 after:h-20 after:border-l-2 after:border-dashed after:border-gray-100 after:left-7 after:z-0 dark:after:border-zinc-600"></div>
                              </div>

                              <div class="shrink-0">
                                <div class="w-20 mr-1 shrink-0 text-end">
                                  <h6 class="mb-1 font-medium text-gray-700 dark:text-gray-100">
                                    -1.5 LTC
                                  </h6>
                                  <div class="text-13 dark:text-zinc-100">
                                    $5791.45
                                  </div>
                                </div>
                              </div>

                              <div class="shrink-0">
                                <div class="relative dropdown">
                                  <a
                                    href="#!"
                                    class="btn py-2.5 dropdown-toggle border-0 text-xl text-gray-700 dark:text-gray-100"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    id="dropdownMenuButton1"
                                  >
                                    <i class="mdi mdi-dots-vertical"></i>
                                  </a>
                                  <ul
                                    class="absolute top-auto z-50 hidden w-40 py-1.5 text-left list-none bg-white border border-gray-50/50 rounded shadow-lg dropdown-menu ltr:!right-0 ltr:!left-auto rtl:!left-0 rtl:!right-auto dark:bg-zinc-700 dark:border-zinc-600 bg-clip-padding"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Action
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Another action
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Something else here
                                      </a>
                                    </li>
                                    <hr class="border-gray-50 my-1.5 dark:border-zinc-600" />
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Separated link
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>

                            <li class="relative flex">
                              <div class="relative pb-7 pl-14 grow">
                                <div class="absolute top-0 z-40 left-2">
                                  <div class="w-10 h-10 text-center rounded-full bg-yellow-50 dark:bg-yellow-500/20 dark:bg-yellow-500/10 ">
                                    <i class="text-2xl leading-relaxed text-yellow-500 bx bx-bitcoin"></i>
                                  </div>
                                </div>

                                <div>
                                  <div class="ml-2 mr-4 overflow-hidden grow lg:w-60">
                                    <h5 class="mb-1 text-sm font-medium text-gray-700 dark:text-gray-100">
                                      24/05/2021, 18:24:56
                                    </h5>
                                    <p class="overflow-hidden text-gray-500 text-13 text-ellipsis whitespace-nowrap dark:text-zinc-100">
                                      0xb77ad0099e21d4fca87fa4ca92dda1a40af9e05d205e53f38bf026196fa2e431
                                    </p>
                                  </div>
                                </div>

                                <div class="after:contant-[] after:absolute after:top-0 after:h-20 after:border-l-2 after:border-dashed after:border-gray-100 after:left-7 after:z-0 dark:after:border-zinc-600"></div>
                              </div>

                              <div class="shrink-0">
                                <div class="w-20 mr-1 shrink-0 text-end">
                                  <h6 class="mb-1 font-medium text-gray-700 dark:text-gray-100">
                                    +0.5 BTC
                                  </h6>
                                  <div class="text-13 dark:text-zinc-100">
                                    $5791.45
                                  </div>
                                </div>
                              </div>

                              <div class="shrink-0">
                                <div class="relative dropdown">
                                  <a
                                    href="#!"
                                    class="btn py-2.5 dropdown-toggle border-0 text-xl text-gray-700 dark:text-gray-100"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    id="dropdownMenuButton1"
                                  >
                                    <i class="mdi mdi-dots-vertical"></i>
                                  </a>
                                  <ul
                                    class="absolute top-auto z-50 hidden w-40 py-1.5 text-left list-none bg-white border border-gray-50/50 rounded shadow-lg dropdown-menu ltr:!right-0 ltr:!left-auto rtl:!left-0 rtl:!right-auto dark:bg-zinc-700 dark:border-zinc-600 bg-clip-padding"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Action
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Another action
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Something else here
                                      </a>
                                    </li>
                                    <hr class="border-gray-50 my-1.5 dark:border-zinc-600" />
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Separated link
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>

                            <li class="relative flex">
                              <div class="relative pl-14 grow">
                                <div class="absolute top-0 z-40 left-2">
                                  <div class="w-10 h-10 text-center rounded-full bg-violet-50 dark:bg-violet-500/20">
                                    <i class="text-2xl leading-relaxed mdi mdi-litecoin text-violet-500"></i>
                                  </div>
                                </div>

                                <div>
                                  <div class="ml-2 mr-4 overflow-hidden grow lg:w-60">
                                    <h5 class="mb-1 text-sm font-medium text-gray-700 dark:text-gray-100">
                                      24/05/2021, 18:24:56
                                    </h5>
                                    <p class="overflow-hidden text-gray-500 text-13 text-ellipsis whitespace-nowrap dark:text-zinc-100">
                                      0xb77ad0099e21d4fca87fa4ca92dda1a40af9e05d205e53f38bf026196fa2e431
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="shrink-0">
                                <div class="w-20 mr-1 shrink-0 text-end">
                                  <h6 class="mb-1 font-medium text-gray-700 dark:text-gray-100">
                                    +.55 LTC
                                  </h6>
                                  <div class="text-13 dark:text-zinc-100">
                                    $91.45
                                  </div>
                                </div>
                              </div>

                              <div class="shrink-0">
                                <div class="relative dropdown">
                                  <a
                                    href="#!"
                                    class="btn py-2.5 dropdown-toggle border-0 text-xl text-gray-700 dark:text-gray-100"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    id="dropdownMenuButton1"
                                  >
                                    <i class="mdi mdi-dots-vertical"></i>
                                  </a>
                                  <ul
                                    class="absolute top-auto z-50 hidden w-40 py-1.5 text-left list-none bg-white border border-gray-50/50 rounded shadow-lg dropdown-menu ltr:!right-0 ltr:!left-auto rtl:!left-0 rtl:!right-auto dark:bg-zinc-700 dark:border-zinc-600 bg-clip-padding"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Action
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Another action
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Something else here
                                      </a>
                                    </li>
                                    <hr class="border-gray-50 my-1.5 dark:border-zinc-600" />
                                    <li>
                                      <a
                                        class="block w-full px-4 py-1.5 text-sm font-normal text-gray-700 bg-transparent dropdown-item whitespace-nowrap hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-zinc-600/80"
                                        href="#"
                                      >
                                        Separated link
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="simplebar-placeholder"></div>
              </div>
              <div class="simplebar-track simplebar-horizontal">
                <div class="simplebar-scrollbar"></div>
              </div>
              <div class="simplebar-track simplebar-vertical">
                <div class="simplebar-scrollbar"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
