import React from "react";

export default function Card({ title, figure, change, duration }) {
  return (
    <div className="card dark:bg-zinc-800 dark:border-zinc-600">
      <div className="card-body">
        <div>
          <div className="grid items-center grid-cols-12 gap-6">
            <div className="col-span-6">
              <span className="text-gray-700 dark:text-zinc-100">{title}</span>
              <h4 className="my-4 font-medium text-gray-800 text-21 dark:text-gray-100">
                ₹
                <span className="counter-value pl-1" data-target={figure}>
                  {figure}
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className="flex items-center mt-1">
          <span className="text-[20px] py-[1px] px-1 bg-green-500/40 text-green-500 rounded font-medium dark:bg-green-500/30">
            {change}
          </span>
          <span className="ltr:ml-1.5 rtl:mr-1.5 text-gray-700 text-sm pl-3 dark:text-zinc-100">
            {duration}
          </span>
        </div>
      </div>
    </div>
  );
}
