import { createSlice } from "@reduxjs/toolkit";

export const DataSlice = createSlice({
  name: "tradeData",
  initialState: {
    indexDetails: [],
    currentIndex: "",
    triggerState: false,
    isHideHeader: false,
    indexLtp: "",
    socketTimeStamp: Date.now(),
    notificationTrigger: false,
  },
  devTools: true,
  reducers: {
    setCurrentIndex: (state, action) => {
      state.currentIndex = "";
      state.currentIndex = action.payload;
    },

    setIndexDetails: (state, action) => {
      state.indexDetails = action.payload;
    },
    setBuyOption: (state, action) => {
      state.triggerState = !state.triggerState;
      let updatedBuyData = [];
      if (!localStorage["Buy_Option"]) {
        updatedBuyData = action.payload;
      } else {
        updatedBuyData = JSON.parse(localStorage["Buy_Option"]);
        updatedBuyData.push(...action.payload);
      }
      localStorage.setItem("Buy_Option", JSON.stringify(updatedBuyData));
    },
    setHideHeader: (state) => {
      state.isHideHeader = !state.isHideHeader;
    },
    setIndexLTP: (state, action) => {
      state.indexLtp = action.payload;
    },
    setSocketTimeStamp: (state, action) => {
      state.socketTimeStamp = action.payload;
    },
    setNotificationTrigger: (state) => {
      state.notificationTrigger = !state.notificationTrigger;
    },
  },
});

export const {
  setCurrentIndex,
  setIndexDetails,
  setBuyOption,
  setHideHeader,
  setIndexLTP,
  setSocketTimeStamp,
  setNotificationTrigger,
} = DataSlice.actions;

export default DataSlice.reducer;
