import { useEffect, useState } from "react";
import DynamicRenderer from "../../Components/DynamicRenderer/DynamicRenderer";
import ApiService from "src/Container/Services/ApiService";
import { useParams } from "react-router";
//api

function DynPage() {
  const { pagekey } = useParams();
  const [jsonData, setJsonData] = useState([]);
  useEffect(() => {
    ApiService.fetchData(`/data/fetch/dyn-page/${pagekey}`).then((data) => {
      setJsonData(data.content);
    });
  }, []);

  return (
    <div className="mt-[200px]">
      <DynamicRenderer templates={jsonData || []} />
    </div>
  );
}

export default DynPage;
