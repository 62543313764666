import { useEffect, useState } from "react";
//api
import ApiService from "src/Container/Services/ApiService";
//ui
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../Components/ui/select";
import { TiArrowSortedUp } from "react-icons/ti";
import { TiArrowSortedDown } from "react-icons/ti";
import { FaRegChartBar } from "react-icons/fa";
import { GoScreenFull } from "react-icons/go";
import { MdOutlineShowChart } from "react-icons/md";

import { useNavigate, useParams } from "react-router";
//components
import OIChart from "./OIChart/OIChart";
//socket
import getSocket from "../../Services/API/Socket";
import { useDispatch, useSelector } from "react-redux";
import {
  setHideHeader,
  setSocketTimeStamp,
} from "src/Container/Redux/Slices/DataSlice";

function OIViewer() {
  const [indexKeys, setIndexKeys] = useState([]);
  const [index, setIndex] = useState(null);
  const [indexExpiry, setIndexExpiry] = useState("");
  const { indexname } = useParams();
  const [oiChartData, setOiChartData] = useState("");
  const navigate = useNavigate();
  const [indexLtp, setIndexLtp] = useState("");
  const [plSummary, setPlSummary] = useState(0);
  const [lastPrice, setLastPrice] = useState("");
  const [lotSize, setLotSize] = useState(0);
  const socket = getSocket();
  const dispatch = useDispatch();
  const [pcr, setPcr] = useState(0);
  const [strikesLimit, setStrikesLimit] = useState(20);
  const limitValues = [10, 20, 30, 40, 50];
  const [showBarChart, setShowBarChart] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    ApiService.fetchData("/data/fetch/indexes")
      .then((data) => {
        const indexes = data.data.indexes;

        const firstIndex = indexes[0];
        if (indexKeys.length === 0) {
          setIndexKeys(indexes.map((key) => key.SEM_TRADING_SYMBOL));
        }

        if (index === null) {
          setIndex(indexname ? indexname : firstIndex.SEM_TRADING_SYMBOL);
          setIndexExpiry(
            indexname
              ? indexes
                  .filter((key) => key.SEM_TRADING_SYMBOL === indexname)
                  .map((data) => data.NEAREST_EXPIRY)
              : firstIndex.NEAREST_EXPIRY
          );
          const firstLtp = indexname
            ? indexes
                .filter((key) => key.SEM_TRADING_SYMBOL === indexname)
                .map((data) => data.LTP)
            : firstIndex.LTP;
          setLastPrice(firstLtp);
          const currentIndexDetails = indexes.find((x) =>
            indexname
              ? x?.SEM_TRADING_SYMBOL === indexname
              : x?.SEM_TRADING_SYMBOL === firstIndex.SEM_TRADING_SYMBOL
          );
          currentIndexDetails?.allStrikes?.optionChain?.forEach((data) => {
            const newData = [
              `${data.strike_price}`,
              data?.call_options?.market_data?.oi || 0,
              data?.put_options?.market_data?.oi || 0,
            ];
            setOiChartData((prevData) => [...prevData, newData]);
          });
          setLotSize(
            indexname
              ? indexes
                  .filter((key) => key.SEM_TRADING_SYMBOL === indexname)
                  .map((data) => data.allStrikes.pcr)
              : firstIndex.allStrikes.pcr
          );
          setPcr(
            indexname
              ? indexes
                  .filter((key) => key.SEM_TRADING_SYMBOL === indexname)
                  .map((data) => data.SEM_LOT_UNITS)
              : firstIndex.SEM_LOT_UNITS
          );
        } else {
          if (setOiChartData.length > 0) {
            setOiChartData([]);
          }
          setIndexExpiry(
            indexes
              .filter((key) => key.SEM_TRADING_SYMBOL === index)
              .map((data) => data.NEAREST_EXPIRY)
          );
          setLastPrice(
            indexes
              .filter((key) => key.SEM_TRADING_SYMBOL === index)
              .map((data) => data.LTP)
          );
          const currentIndexDetails = indexes.find(
            (x) => x?.SEM_TRADING_SYMBOL === index
          );
          currentIndexDetails?.allStrikes?.optionChain?.forEach((data) => {
            const newData = [
              `${data.strike_price}`,
              data?.call_options?.market_data?.oi || 0,
              data?.put_options?.market_data?.oi || 0,
            ];
            setOiChartData((prevData) => [...prevData, newData]);
          });
          setLotSize(
            indexes
              .filter((key) => key.SEM_TRADING_SYMBOL === index)
              .map((data) => data.SEM_LOT_UNITS)
          );
          setPcr(
            indexes
              .filter((key) => key.SEM_TRADING_SYMBOL === index)
              .map((data) => data.allStrikes.pcr)
          );
        }
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
  }, [index]);
  useEffect(() => {
    socket.on(`IDX_${index}`, (data) => {
      if (data.LTP) {
        dispatch(setSocketTimeStamp(Date.now()));
        setIndexLtp(data.LTP);
        const profitLoss = isNaN(data?.close - data?.open)
          ? 0
          : (data?.close || 0) - (data?.open || 0);
        setPlSummary((prevState) => {
          if (profitLoss === 0) {
            return prevState;
          } else {
            return profitLoss;
          }
        });
      }
    });
    return () => {
      socket.off(`IDX_${index}`);
      setIndexLtp("");
    };
  }, [socket, index]);

  const handleIndex = (index) => {
    setIndex(index);
    navigate(`/oi-chart/open-interest/${index}`);
  };

  const handleLimit = (limit) => {
    setStrikesLimit(limit);
  };
  return (
    <div className={`p-3 flex flex-col`}>
      <div className="border p-3 flex justify-between">
        <div className="flex gap-3 ">
          <div className="flex flex-col">
            <div className="text-sm">Symbol</div>
            <Select onValueChange={handleIndex}>
              <SelectTrigger className="w-[180px]">
                <SelectValue defaultValue={index} placeholder={index} />
              </SelectTrigger>
              <SelectContent className="z-[1001]">
                {indexKeys.map((data) => (
                  <SelectItem
                    key={data}
                    value={data}
                    className="cursor-pointer p-3 z-10 hover:bg-sky-100"
                  >
                    {data}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          {/* <div className="flex flex-col">
          <div className="text-sm">Expiry</div>
          <div className="border px-3 py-2  justify-center items-center text-sm">
            <span>{indexExpiry}</span>
          </div>
        </div> */}
          <div className="flex flex-col">
            <div className="text-sm">Strikes Limit</div>
            <Select onValueChange={handleLimit}>
              <SelectTrigger className="w-[180px]">
                <SelectValue
                  defaultValue={strikesLimit}
                  placeholder={strikesLimit}
                />
              </SelectTrigger>
              <SelectContent className="z-[1001]">
                {limitValues.map((data) => (
                  <SelectItem
                    key={data}
                    value={data}
                    className="cursor-pointer p-3 z-10 hover:bg-sky-100"
                  >
                    {data}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col justify-between w-[150px]">
            <div className="text-sm">Spot Price</div>
            <div className="flex">
              <span className="flex-1">{indexLtp ? indexLtp : lastPrice}</span>
              <div className="flex flex-col justify-center">
                {plSummary && plSummary > 0 ? (
                  <TiArrowSortedUp color="green" />
                ) : (
                  <TiArrowSortedDown color="red" />
                )}
              </div>
              <div>
                {plSummary && plSummary > 0 ? (
                  <div className="text-green-500">{plSummary.toFixed(2)}</div>
                ) : (
                  <div className="text-red-500">{plSummary.toFixed(2)}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-5">
          <div
            className="h-full flex flex-col justify-center cursor-pointer"
            onClick={() => setShowBarChart(!showBarChart)}
          >
            {!showBarChart ? (
              <>
                <FaRegChartBar size={30} color="blue" />
                <span className="text-sm text-blue-500">Bar Chart</span>
              </>
            ) : (
              <>
                <MdOutlineShowChart size={30} color="blue" />
                <span className="text-sm text-blue-500">Line Chart</span>
              </>
            )}
          </div>
          <div className="h-full flex flex-col justify-center cursor-pointer">
            <GoScreenFull
              size={30}
              onClick={() => dispatch(setHideHeader(true))}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-3 border-t-0 border-b-2 border-r-2 border-l-2">
        <div className="text-sm text-gray-500 p-3 ">
          Lot Size: <span className="text-black">{lotSize}</span>
        </div>
        <div className="text-sm text-gray-500 p-3 border-l-2">
          PCR(ALL): <span className="text-black">{Number(pcr).toFixed(2)}</span>
        </div>
      </div>

      <div className="mt-3 border">
        <OIChart
          oiChartData={oiChartData}
          indexLtp={indexLtp ? indexLtp : lastPrice}
          strikesLimit={strikesLimit}
          showBarChart={showBarChart}
        ></OIChart>
      </div>
    </div>
  );
}

export default OIViewer;
