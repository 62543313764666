import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, Outlet } from "react-router-dom";

function OIHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const isHideHeader = useSelector((state) => state.tradeData.isHideHeader);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <nav
        className={`p-1 border  ${
          isHideHeader ? "" : "mt-[150px] lg:mt-[100px]"
        }`}
      >
        <div className="mx-auto flex justify-between items-center">
          <div className="lg:hidden">
            <button
              onClick={toggleMenu}
              className="text-gray-600 focus:outline-none"
              aria-controls="navbar-dropdown"
              aria-expanded={isOpen}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={
                    isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"
                  }
                />
              </svg>
            </button>
          </div>
          <div
            className={`lg:flex lg:items-center ${
              isOpen ? "block" : "hidden"
            } w-full lg:w-auto`}
            id="navbar-dropdown"
          >
            <NavLink
              to="/oi-chart/open-interest"
              className={({ isActive }) =>
                isActive
                  ? " text-green-500 bg-green-50 p-3 block hover:text-green-300 lg:mx-2"
                  : "text-gray-400 hover:text-green-300 p-3 block lg:mx-2"
              }
            >
              Open Interest
            </NavLink>
            <NavLink
              to="/oi-chart/change-io"
              className={({ isActive }) =>
                isActive
                  ? " text-green-500  bg-green-50 p-3  block hover:text-green-300 lg:mx-2"
                  : "text-gray-400 hover:text-green-300 p-3 lg:mx-2 block"
              }
            >
              Change in OI
            </NavLink>
            <NavLink
              to="/oi-chart/put-call"
              className={({ isActive }) =>
                isActive
                  ? " text-green-500  bg-green-50 p-3  lg:mx-2 block hover:text-green-300"
                  : "text-gray-400 hover:text-green-300 p-3 lg:mx-2 block"
              }
            >
              Put Call Ratio
            </NavLink>
            <NavLink
              to="/oi-chart/volume-pcr"
              className={({ isActive }) =>
                isActive
                  ? " text-green-500  bg-green-50 p-3  lg:mx-2 block hover:text-green-300"
                  : "text-gray-400 hover:text-green-300 p-3 lg:mx-2 block"
              }
            >
              Volume PCR
            </NavLink>
          </div>
        </div>
      </nav>

      <Outlet />
    </>
  );
}

export default OIHeader;
