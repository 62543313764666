import React from "react";

const DynamicRenderer = ({ templates }) => {
  // Function to dynamically replace placeholders in the template
  const renderTemplate = (templateString, dataObj) => {
    return templateString.replace(/{(.*?)}/g, (_, key) => {
      return key.split(".").reduce((o, i) => o[i], dataObj);
    });
  };

  // Function to handle actions based on type
  const handleAction = (action) => {
    if (action.type === "redirect") {
      window.location.href = action.target;
    }
    // Additional action types can be handled here
  };

  return (
    <div>
      {templates.map((templateObj, index) => {
        const { template, data } = templateObj;

        if (template.type === "list") {
          // Iterate through data array
          return (
            <div className={template.wrapperClass || ""}>
              {data.map((item, idx) => {
                const htmlString = renderTemplate(template.__rawhtml, { item });
                return (
                  <div
                    className={item.wrapperClass || "flex flex-col"}
                    key={`${index}-${idx}`}
                    dangerouslySetInnerHTML={{ __html: htmlString }}
                    onClick={() => handleAction(item.action)}
                    style={{ cursor: item.action ? "pointer" : "default" }}
                  />
                );
              })}
            </div>
          );
        } else if (template.type === "single") {
          // Process single item
          const htmlString = renderTemplate(template.__rawhtml, {
            data: data.data,
          });
          return (
            <div
              className={template.wrapperClass || ""}
              key={index}
              dangerouslySetInnerHTML={{ __html: htmlString }}
              onClick={() => handleAction(data.action)}
              style={{ cursor: data.action ? "pointer" : "default" }}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default DynamicRenderer;
