import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

function Footer() {
  const location = useLocation();
  const onHandleTutorialClick = () => {
    if (location.pathname.includes("oi-analysis")) {
      localStorage.removeItem("run");
    } else if (location.pathname.includes("option-chain")) {
      localStorage.removeItem("run_option");
    }
  };
  return (
    <footer className="theme-footer w-full">
      <div className="container">
        <div className="inner-wrapper">
          <div className="top-footer-data-wrapper">
            <div className="row">
              <div className="col-lg-4 col-sm-6 footer-logo">
                <div className="logo">
                  <a href="/">
                    <img
                      src="/static/media/logo.13bcadde91a5e200176c.png"
                      alt="Logo"
                      style={{ maxWidth: "100px" }}
                    />
                  </a>
                </div>
                <a href="#" className="email">
                  hello@profitolio.app
                </a>
                <a href="#" className="mobile">
                  {/* +91-95352 71474 */}
                </a>
              </div>{" "}
              {/* /.footer-logo */}
              <div className="col-lg-2 col-sm-6 footer-list">
                <h4 className="title">Quick Links</h4>
                <ul>
                  <li onClick={() => localStorage.removeItem("run")}>
                    <a href="" onClick={onHandleTutorialClick}>
                      How it Works
                    </a>
                  </li>
                  <li>
                    <a href="#">Products</a>
                  </li>
                  <li>
                    <a href="#">Report Bug / Vulnerability</a>
                  </li>
                  <li>
                    <a href="#">Pricing</a>
                  </li>
                </ul>
              </div>{" "}
              {/* /.footer-list */}
              <div className="col-lg-3 col-sm-6 footer-list">
                <h4 className="title">About Us</h4>
                <ul>
                  <li>
                    <a href="#">About Profitolio</a>
                  </li>
                  <li>
                    <a href="#">Jobs</a>
                  </li>
                  <li>
                    <a href="#">Team</a>
                  </li>
                  <li>
                    <a href="#">Blog</a>
                  </li>
                </ul>
              </div>{" "}
              {/* /.footer-list */}
              <div className="col-lg-3 col-sm-6 footer-list">
                <h4 className="title">Become A Member</h4>
                <ul>
                  <li>
                    <a href="#">Free</a>
                  </li>
                  <li>
                    <a href="#">Premium</a>
                  </li>
                  <li>
                    <a href="#">Platinum</a>
                  </li>
                </ul>
              </div>{" "}
              {/* /.footer-list */}
            </div>{" "}
            {/* /.row */}
          </div>{" "}
          {/* /.top-footer-data-wrapper */}
          <div className="bottom-footer clearfix">
            <p className="copyright">
              &copy; 2018 <a href="#">Profitolio.</a> All Right Reserved
            </p>
            <ul>
              <li>
                <a href="#">
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-twitter" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>{" "}
          {/* /.bottom-footer */}
        </div>
      </div>{" "}
      {/* /.container */}
    </footer>
  );
}

export default Footer;
