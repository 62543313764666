import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../Components/ui/table";
import { FaPlus } from "react-icons/fa";
import Popup from "reactjs-popup";
//component
import CreateUser from "src/Container/Pages/Admin/Users/CreateUser/CreateUser";
//api
import ApiService from "src/Container/Services/ApiService";
//loader
import ClipLoader from "react-spinners/ClipLoader";

export default function Users() {
  const [isPopping, setIsPopping] = useState(false);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checkedUsers, setCheckedUsers] = useState([]);

  useEffect(() => {
    ApiService.fetchData("/manager/users/list")
      .then((data) => {
        setUserData(data.data);
        setLoading(false);
        setCheckedUsers(data.data.map(() => true));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleChange = (index) => {
    setCheckedUsers((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };
  return loading ? (
    <div className="flex justify-center items-center w-full">
      <ClipLoader
        color="blue"
        loading={loading}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  ) : (
    <div className="container-fluid px-[0.625rem]">
      <div className="flex justify-between  px-[0.625rem] py-1">
        <h4 className="text-[18px] font-medium text-gray-800 mb-sm-0 grow dark:text-gray-100 mb-2 md:mb-0 p-2">
          Users
        </h4>
        <div
          className="p-2 bg-teal-400 rounded-md flex justify-center items-center cursor-pointer"
          onClick={() => setIsPopping(true)}
        >
          <FaPlus color="white" />
        </div>
      </div>
      <div>
        <Table>
          {/* <TableCaption>A list of your recent invoices.</TableCaption> */}
          <TableHeader>
            <TableRow>
              <TableHead>Id</TableHead>
              <TableHead>First Name</TableHead>
              <TableHead>Last Name</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Active</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {userData &&
              userData.map((data, index) => {
                return (
                  <TableRow>
                    <TableCell className="font-medium" key={data.id}>
                      {data.id}
                    </TableCell>
                    <TableCell>{data.firstName}</TableCell>
                    <TableCell>{data.lastName}</TableCell>
                    <TableCell>{data.email}</TableCell>
                    <TableCell>
                      <label className="inline-flex items-center me-5 cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          checked={checkedUsers[index]} // Set checked based on state
                          onChange={() => handleChange(index)} // Handle checkbox change
                        />
                        <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
                      </label>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
      <Popup
        open={isPopping}
        onClose={() => setIsPopping(false)}
        position="right center"
        className="popup-overlay"
      >
        {isPopping && <CreateUser setIsPopping={setIsPopping} />}
      </Popup>
    </div>
  );
}
