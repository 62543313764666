import React, { useState } from "react";
//api
import ApiService from "src/Container/Services/ApiService";
//validator
import * as Validator from "validatorjs";
//toast
import { toast } from "sonner";

export default function CreateUser({ setIsPopping }) {
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    mobile_no: "",
    dhanClientId: "",
    maxCapitalForTrade: "",
    UCC: "",
  });
  const [validationErrors, setValidationErrors] = useState({});

  const validateForm = (fieldname) => {
    let rules = {
      email: "required|email",
      firstName: "required|min:3",
      lastName: "required|min:3",
      mobile_no: "required|numeric:10",
      dhanClientId: "required",
      maxCapitalForTrade: "required",
      UCC: "required",
    };
    const validation = new Validator(formData, rules, {
      "required.UCC": "ucc is required.",
      "required.firstName": "first name is required.",
    });

    Validator.setAttributeFormatter(function (attribute) {
      let formattedAttribute = attribute.replace(/_/g, " ");
      formattedAttribute = formattedAttribute
        .replace(/([A-Z])/g, " $1")
        .toLowerCase();

      return formattedAttribute;
    });
    validation.passes();
    if (validation.fails()) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [fieldname]: validation.errors.first(fieldname),
      }));
      return false;
    }
    // Clear validation error if validation passes for the field
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldname]: "",
    }));
    return true;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBlur = (e) => {
    validateForm(e.target.name);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsPopping(false);
      ApiService.postData("/manager/users/add", formData)
        .then((data) => {
          if (data.success) {
            toast.success("User added successfully");
            alert("User added successfully");
          } else {
            toast.error(data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("Form has validation errors");
      Object.keys(formData).forEach((key) => {
        validateForm(key);
      });
    }
  };

  return (
    <form
      className="flex flex-col bg-white rounded px-5 py-3 space-y-4"
      onSubmit={handleSubmit}
    >
      <header className="mx-auto text-[18px] font-medium text-gray-800 mb-sm-0 grow dark:text-gray-100 text-center">
        Create a User
      </header>
      <div className="flex flex-col md:flex-row md:space-x-4">
        <div className="flex-1">
          <label className="text-sm font-medium text-gray-700">
            First Name:
          </label>
          <input
            name="firstName"
            type="text"
            value={formData.firstName}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
          {validationErrors.firstName && (
            <span className="mt-1 text-sm text-red-400">
              *{validationErrors.firstName}
            </span>
          )}
        </div>
        <div className="flex-1">
          <label className="text-sm font-medium text-gray-700">
            Last Name:
          </label>
          <input
            name="lastName"
            type="text"
            value={formData.lastName}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
          {validationErrors.lastName && (
            <span className="mt-1 text-sm text-red-400">
              *{validationErrors.lastName}
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:space-x-4">
        <div className="flex-1">
          <label className="text-sm font-medium text-gray-700">Email:</label>
          <input
            name="email"
            type="email"
            value={formData.email}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
          {validationErrors.email && (
            <span className="mt-1 text-sm text-red-400">
              *{validationErrors.email}
            </span>
          )}
        </div>
        <div className="flex-1">
          <label className="text-sm font-medium text-gray-700">
            Mobile Number:
          </label>
          <input
            name="mobile_no"
            type="text"
            value={formData.mobile_no}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
          {validationErrors.mobile_no && (
            <span className="mt-1 text-sm text-red-400">
              *{validationErrors.mobile_no}
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:space-x-4">
        <div className="flex-1">
          <label className="text-sm font-medium text-gray-700">
            Dhan Client Id:
          </label>
          <input
            name="dhanClientId"
            type="text"
            value={formData.dhanClientId}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
          {validationErrors.dhanClientId && (
            <span className="mt-1 text-sm text-red-400">
              *{validationErrors.dhanClientId}
            </span>
          )}
        </div>
        <div className="flex-1">
          <label className="text-sm font-medium text-gray-700">UCC:</label>
          <input
            name="UCC"
            type="text"
            value={formData.UCC}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
          {validationErrors.UCC && (
            <span className="mt-1 text-sm text-red-400">
              *{validationErrors.UCC}
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:space-x-4">
        <div className="flex-1">
          <label className="text-sm font-medium text-gray-700">
            Max Capital For Trade:
          </label>
          <input
            name="maxCapitalForTrade"
            type="text"
            value={formData.maxCapitalForTrade}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
          {validationErrors.maxCapitalForTrade && (
            <span className="mt-1 text-sm text-red-400">
              *{validationErrors.maxCapitalForTrade}
            </span>
          )}
        </div>
      </div>
      <button
        className="mx-auto mt-4 px-3 py-1 bg-green-700 text-white rounded"
        type="submit"
      >
        Create a User
      </button>
    </form>
  );
}
