import { TbPlayerStopFilled } from "react-icons/tb";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBuyOption } from "../../Redux/Slices/DataSlice";
import getSocket from "../../Services/API/Socket";

function TradeSummary({ isLoggedIn }) {
  const triggerState = useSelector((state) => state.tradeData.triggerState);
  const socket = getSocket();
  const [checkoutList, setCheckoutList] = useState([]);
  const [optionChainData, setOptionChainData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const savedCheckoutList = localStorage.getItem("Buy_Option");
    if (savedCheckoutList) {
      setCheckoutList(JSON.parse(savedCheckoutList));
    }
  }, [triggerState]);

  useEffect(() => {
    const summaryData =
      localStorage.getItem("Buy_Option") &&
      JSON.parse(localStorage.getItem("Buy_Option"));

    const handleData = (data, type) => {
      if (
        data.SEM_INSTRUMENT_NAME === "OPTIDX" &&
        data.type === "Quote Data" &&
        data.SEM_OPTION_TYPE === type
      ) {
        setOptionChainData((prevData) => UpdateData(prevData, data));
      }
    };

    summaryData?.forEach((element) => {
      const eventName = `OPTIDX_${element.Index}_${element.Strike_Price}`;
      socket.on(eventName, (data) => handleData(data, element.Type));
    });

    return () => {
      summaryData?.forEach((element) => {
        const eventName = `OPTIDX_${element.Index}_${element.Strike_Price}`;
        socket.off(eventName);
      });
    };
  }, [socket, triggerState]);

  function UpdateData(currentData, newData) {
    const { SEM_STRIKE_PRICE, LTP, index } = newData;
    const updatedData = [...currentData];
    const existingIndex = updatedData.findIndex(
      (item) => item.strikePrice === SEM_STRIKE_PRICE
    );
    if (existingIndex === -1) {
      updatedData.push({
        index,
        strikePrice: SEM_STRIKE_PRICE,
        LTP: LTP || "",
      });
    } else {
      updatedData[existingIndex] = {
        ...updatedData[existingIndex],
        LTP: LTP !== undefined ? LTP : updatedData[existingIndex].LTP,
        strikePrice:
          SEM_STRIKE_PRICE !== undefined
            ? SEM_STRIKE_PRICE
            : updatedData[existingIndex].strikePrice,
      };
    }
    return updatedData;
  }
  const onClickStop = (sellPoint, balance, index) => {
    const data = JSON.parse(localStorage.getItem("Buy_Option"));
    data[index].Status = "closed";
    data[index].Sold_Price = sellPoint;
    data[index].Profit_Loss = balance;
    localStorage.removeItem("Buy_Option");
    dispatch(setBuyOption(data));
  };

  return (
    localStorage.getItem("Buy_Option") &&
    isLoggedIn && (
      <div className="p-3 border-b max-h-[200px] overflow-y-scroll mt-[100px]">
        {checkoutList.map((data, index) => {
          const matchedData = optionChainData.find(
            (option) => Number(option.strikePrice) === Number(data.Strike_Price)
          );

          const cmpValue = matchedData ? matchedData.LTP : 0;
          const buyPrice = data.LTP || 0;
          const totalQuantity = data.Total_Quantity || 0;
          const profitLoss =
            data.Status === "open"
              ? ((cmpValue - buyPrice) * totalQuantity).toFixed(2)
              : 0;
          return (
            <div
              key={index}
              className="flex justify-between p-2 ring-2 ring-blue-500/50 rounded mt-3"
            >
              <div className="flex items-center text-xl text-gray-700">
                {data.Custom_Symbol}
              </div>
              <div className="flex flex-col">
                <div className="font-bold text-gray-700">QTY</div>
                <div>{data.Total_Quantity}</div>
              </div>
              <div className="flex flex-col text-gray-700">
                <div className="font-bold">BUY</div>
                <div>{data.LTP}</div>
              </div>
              <div className="flex flex-col text-gray-700">
                <div className="font-bold">
                  {data.Sold_Price ? "SELL" : "CMP"}
                </div>
                <div
                  className={
                    data.Sold_Price
                      ? data.Sold_Price > data.LTP
                        ? "text-green-700"
                        : data.Sold_Price < data.LTP
                        ? "text-red-600"
                        : "text-yellow-600"
                      : data.LTP < cmpValue
                      ? "text-green-700"
                      : data.LTP > cmpValue
                      ? "text-red-600"
                      : "text-yellow-600"
                  }
                >
                  {data.Sold_Price ? data.Sold_Price : cmpValue}
                </div>
              </div>
              <div className="flex flex-col text-gray-700">
                <div className="font-bold">P/L</div>
                <div
                  className={
                    data.Profit_Loss
                      ? data.Profit_Loss >= 0
                        ? "text-green-700"
                        : "text-red-600"
                      : profitLoss >= 0
                      ? "text-green-700"
                      : "text-red-500"
                  }
                >
                  {data.Profit_Loss ? data.Profit_Loss : profitLoss}
                </div>
              </div>
              <div className="flex flex-col text-gray-700">
                <div className="font-bold text-gray-700">Status</div>
                <div className="text-gray-700">{data.Status}</div>
              </div>
              <div className="flex items-center">
                <TbPlayerStopFilled
                  color="red"
                  size={30}
                  onClick={() => onClickStop(cmpValue, profitLoss, index)}
                />
              </div>
            </div>
          );
        })}
      </div>
    )
  );
}

export default TradeSummary;
