import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Store from "./Container/Redux/Store/Store";
import { Provider } from "react-redux";
//components
import Layout from "./Container/Layout/Layout/Layout";
import AdminLayout from "./Container/Layout/Layout/AdminLayout";
import TradePage from "./Container/Layout/TradePage/TradePage";
import LandingPage from "./Container/Pages/LandingPage/LandingPage";
import Login from "./Container/Pages/Login/Login";
import Controls from "./Container/Pages/Controls/Controls";
import LineChartDisplay from "./Container/Pages/LineChartDisplay/LineChartDisplay";
import AdminDashboard from "./Container/Pages/Admin/Dashboard/Dashboard";
import Users from "./Container/Pages/Admin/Users/User";
import Orders from "./Container/Pages/Admin/Orders/Orders";
import Positions from "./Container/Pages/Admin/Positions/Positions";
import Settings from "./Container/Pages/Admin/Settings/Settings.js";
import PaperTrading from "./Container/Pages/Admin/PaperTrading/PaperTrading";
import PivotTable from "./Container/Pages/PivotTable/PivotTable";
import { Toaster } from "../src/Container/Components/ui/sonner";
import MessageAlert from "./Container/Components/MessageAlert/MessageAlert";
import OIViewer from "./Container/Pages/OIViewer/OIViewer";
import SocketControl from "./Container/Pages/Admin/Settings/SocketControl.js";
import OIHeader from "./Container/Layout/OIHeader/OIHeader";
import DynPage from "./Container/Pages/DynPage/DynPage";
function App() {
  return (
    <>
      <Toaster richColors position="top-right"></Toaster>
      <BrowserRouter>
        <Routes>
          <Route path="/manage" element={<AdminLayout />}>
            <Route path="" element=<Navigate to="dashboard" /> />
            <Route path="dashboard" index element={<AdminDashboard />} />
            <Route path="positions" index element={<Positions />} />
            <Route path="settings" index element={<Settings />} />
            <Route path="socket-control" index element={<SocketControl />} />
            <Route path="orders" index element={<Orders />} />
            <Route path="users" index element={<Users />} />
            <Route path="oi-analysis" index element={<LineChartDisplay />} />
            <Route
              path="oi-analysis/:selectedIndex"
              index
              element={<LineChartDisplay />}
            />
            <Route
              path="paper-trading/:indexname?"
              element={<PaperTrading />}
            />
          </Route>
          <Route path="/" element={<Layout />}>
            {/* <Route path="/" element=<Navigate to="/oi-analysis" /> /> */}
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />

            <Route path="/dyn-page/:pagekey" element={<DynPage />} />
            <Route
              path="/option-chain/:indexname?"
              index
              element={<TradePage />}
            />
            <Route path="/controls" index element={<Controls />} />
            <Route path="oi-analysis" index element={<LineChartDisplay />} />
            <Route path="/stock-screener" index element={<PivotTable />} />
            <Route
              path="/oi-analysis/:selectedIndex"
              index
              element={<LineChartDisplay notLoggedIn={true} />}
            />
            <Route path="/oi-chart" element={<OIHeader />}>
              <Route
                index
                element={<Navigate to="/oi-chart/open-interest" />}
              />
              <Route path="open-interest/:indexname?" element={<OIViewer />} />
            </Route>
          </Route>

          <Route
            path="*"
            element={
              <h1 className="min-h-[500px] flex justify-center items-center text-red-500 text-[50px]">
                404 Not Found
              </h1>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
