import React from "react";
import Card from "../../../Components/Admin/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaChevronRight } from "react-icons/fa6";

export default function Positions() {
  return (
    <div className="container-fluid px-[0.625rem]">
      <div className="grid grid-cols-1 pb-6">
        <div className="md:flex items-center justify-between px-[2px]">
          <h4 className="text-[18px] font-medium text-gray-800 mb-sm-0 grow dark:text-gray-100 mb-2 md:mb-0">
            Positions
          </h4>
          <nav className="flex" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 ltr:md:space-x-3 rtl:md:space-x-0">
              <li className="inline-flex items-center">
                <a
                  href="#"
                  className="inline-flex items-center text-sm text-gray-800 hover:text-gray-900 dark:text-zinc-100 dark:hover:text-white"
                >
                  Positions
                </a>
              </li>
              <li>
                <div className="flex items-center rtl:mr-2">
                  <FontAwesomeIcon icon={FaChevronRight} />
                  <a
                    href="#"
                    className="text-sm font-medium text-gray-500 ltr:ml-2 rtl:mr-2 hover:text-gray-900 ltr:md:ml-2 rtl:md:mr-2 dark:text-gray-100 dark:hover:text-white"
                  >
                    Positions
                  </a>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-6 gap-y-0 2xl:gap-6 md:grid-cols-2 2xl:grid-cols-4"></div>
    </div>
  );
}
