import { Outlet } from "react-router";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import MessageAlert from "src/Container/Components/MessageAlert/MessageAlert";

function Layout() {
  return (
    <div className="main-page-wrapper">
      <div className="html-top-content">
        <Header></Header>
        <Outlet></Outlet>
        <MessageAlert></MessageAlert>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default Layout;
