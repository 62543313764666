import { useEffect, useState } from "react";
//socket
import getSocket from "../../Services/API/Socket";
//redux
import { useSelector } from "react-redux";
//Components
import { UpdateData } from "../../Components/UpdateData/UpdateData";
// import { useErrorBoundary } from "react-error-boundary";
//popup
import Popup from "reactjs-popup";
//joyride
import Joyride, { STATUS } from "react-joyride";
//component
import InstantOptionsCard from "src/Container/Components/InstantOptionsCard/InstantOptionsCard";

function TradeList({ isLoggedIn }) {
  const currentIndex = useSelector((state) => state.tradeData.currentIndex);
  const indexLtp = useSelector((state) => state.tradeData.indexLtp);
  const socket = getSocket();
  const indexDetails = useSelector((state) => state.tradeData.indexDetails);
  const [optionChainData, setOptionChainData] = useState([]);
  const [isPopping, setIsPopping] = useState(false);
  const [type, setType] = useState("");
  const [strikeRate, setStrikeRate] = useState("");
  const [lastPrice, setLastPrice] = useState("");
  const [isFixed, setIsFixed] = useState(false);
  // const { showBoundary } = useErrorBoundary();

  const steps = [
    {
      target: ".my-first-step",
      content:
        "Click on the price to do paper trading. You need to be logged in to use this feature.",
      disableBeacon: true,
      position: "center",
    },
  ];
  const strikePrices = indexDetails
    .filter((data) => data.SEM_TRADING_SYMBOL === currentIndex)
    .map((strikePrice) => strikePrice.ACTIVE_STRIKES);

  const processHistoryStrikePrice = () => {
    setOptionChainData([]);
    const currentIndexDetails = indexDetails.filter(
      (x) => x?.SEM_TRADING_SYMBOL == currentIndex
    );
    currentIndexDetails[0]?.allStrikes?.optionChain?.forEach((data) => {
      const newData = {
        strikePrice: data.strike_price,
        Volume_CE: data?.call_options?.market_data?.volume || 0,
        Volume_PE: data?.put_options?.market_data?.volume || 0,
        OI_CE: data?.call_options?.market_data?.oi || 0,
        OI_PE: data?.put_options?.market_data?.oi || 0,
        LTP_CE: data?.call_options?.market_data?.ltp || 0,
        LTP_PE: data?.put_options?.market_data?.ltp || 0,
      };

      setOptionChainData((prevData) => {
        return [...prevData, newData];
      });
    });
  };

  const processStrikeOI = (data) => {
    if (data.SEM_INSTRUMENT_NAME === "OPTIDX") {
      // const memoizedUpdateData = memoize(UpdateData);
      const updatedData = UpdateData(data);
      setOptionChainData((prevData) =>
        updatedData.map((e, index) => {
          const updatedElement = prevData?.[index] || {};
          // console.log(e.LTP_PE);
          // console.log(updatedElement.LTP_PE);
          return {
            ...e,
            color_PE:
              +e.LTP_PE < +updatedElement?.LTP_PE ? "#ed3e3e" : "#4CBF72",
            color_CE:
              +e.LTP_CE < +updatedElement?.LTP_CE ? "#ed3e3e" : "#4CBF72",
          };
        })
      );
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setLastPrice(
      indexDetails
        ? indexDetails
            .filter((key) => key.SEM_TRADING_SYMBOL === currentIndex)
            .map((data) => data.LTP)
        : ""
    );
    processHistoryStrikePrice();
  }, [currentIndex]);

  useEffect(() => {
    strikePrices[0]?.forEach((strikePrice) => {
      socket.on(`OPTIDX_${currentIndex}_${strikePrice}`, processStrikeOI);
    });

    return () => {
      strikePrices[0]?.forEach((strikePrice) => {
        socket.off(`OPTIDX_${currentIndex}_${strikePrice}`, processStrikeOI);
      });
    };
  }, [socket, currentIndex]);

  useEffect(() => {
    if (indexLtp || lastPrice) {
      const chartElement = document.querySelector(`[aria-label="index"]`);
      if (chartElement) {
        chartElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }
  }, [lastPrice]);
  const onHandleLTPClick = (strikePrice, type) => {
    if (isLoggedIn) {
      setType(type);
      setIsPopping(true);
      setStrikeRate(strikePrice);
    }
  };

  const handleJoyrideCallback = (data) => {
    if (data.status === STATUS.FINISHED) {
      localStorage.setItem("run_option", false);
    }
  };

  const Tooltip = ({ continuous, step, primaryProps, tooltipProps }) =>
    continuous && (
      <div {...tooltipProps} className="bg-blue-50 p-3  ">
        <div>{step.content}</div>
        <div className="flex justify-center">
          {continuous && (
            <button
              {...primaryProps}
              id="next"
              className="px-3 bg-blue-500 rounded-sm mt-3 text-white font-bold"
              onClick={primaryProps.onClick}
            >
              ok
            </button>
          )}
        </div>
      </div>
    );

  return (
    <div className="p-4 my-first-step">
      {!localStorage["run_option"] && (
        <Joyride
          tooltipComponent={Tooltip}
          steps={steps}
          continuous="true"
          callback={handleJoyrideCallback}
          disableCloseOnEsc="false"
          styles={{
            options: {
              arrowColor: "#fff",
              backgroundColor: "#fff",
              overlayColor: "transparent",
              primaryColor: "#f04",
              spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
              textColor: "#333",
              zIndex: 50,
            },
          }}
        />
      )}
      <table
        className="w-full"
        style={{
          marginTop: isLoggedIn
            ? localStorage.getItem("Buy_Option")
              ? "0px"
              : "70px"
            : "50px",
        }}
      >
        <thead
          className="border-b bg-white z-10 flex justify-around  "
          style={{
            position: isFixed ? "fixed" : "block",
            top: isFixed ? (isLoggedIn ? "88px" : "100px") : "auto",
            paddingTop: isFixed ? (isLoggedIn ? "0px" : "80px") : 0,
            width: "-webkit-fill-available",
          }}
        >
          <th className="py-2 font-light text-gray-600 text-left">OI</th>
          <th className="py-2 font-light text-gray-600 text-left">Volume</th>
          <th className="py-2 font-light text-gray-600 text-left">LTP</th>
          <th className="p-2">
            <div className="w-3/4 flex justify-center border border-black rounded font-bold">
              CE
            </div>
          </th>
          <th className="py-2 font-light text-gray-600 text-center">
            Strike Price
          </th>
          <th className="p-2">
            <div className="w-3/4 flex justify-center border border-black rounded font-bold">
              PE
            </div>
          </th>
          <th className="py-2 font-light text-gray-600 text-left">LTP</th>
          <th className="py-2 font-light text-gray-600 text-center">Volume</th>
          <th className="py-2 font-light text-gray-600 text-right">OI</th>
        </thead>
        <tbody className="">
          {strikePrices[0] &&
            strikePrices[0].map((strikePrice, strikeIndex) => {
              const matchedData =
                optionChainData &&
                optionChainData.find(
                  (data) => data.strikePrice === strikePrice
                );
              const isIndexLtpRow = indexLtp
                ? indexLtp !== "" &&
                  strikeIndex > 0 &&
                  Number(strikePrices[0][strikeIndex]) > Number(indexLtp) &&
                  Number(strikePrices[0][strikeIndex - 1]) < Number(indexLtp)
                : lastPrice[0] !== "" &&
                  strikeIndex > 0 &&
                  Number(strikePrices[0][strikeIndex]) > Number(lastPrice[0]) &&
                  Number(strikePrices[0][strikeIndex - 1]) <
                    Number(lastPrice[0]);

              // Array to hold rows to be rendered
              const rows = [];
              // Check the condition and insert the row for indexPrice if condition satisfies
              if (isIndexLtpRow) {
                rows.push(
                  <tr key={`indexPrice-${strikeIndex}`} className="bg-sky-100">
                    <td className="p-2 flex gap-1">
                      <p></p>
                    </td>
                    <td className="p-2"></td>
                    <td className="p-2"></td>
                    <td className="p-2"></td>
                    <td
                      className="p-2 text-center text-white bg-blue-700 rounded font-bold "
                      aria-label="index"
                    >
                      {indexLtp ? indexLtp : lastPrice[0]}
                    </td>
                    <td className="p-2"></td>
                    <td className="p-2"></td>
                    <td className="p-2"></td>
                    <td className="p-2 flex gap-1 justify-end">
                      <p></p>
                    </td>
                  </tr>
                );
              }

              // Insert the current row
              rows.push(
                <tr key={strikeIndex} className="group hover:!bg-teal-300  ">
                  <td
                    className={`p-2 ${
                      indexLtp > strikePrice ? "bg-sky-50" : ""
                    } group-hover:!bg-teal-200`}
                  >
                    <p>{matchedData ? matchedData.OI_CE : ""}</p>
                  </td>
                  <td
                    className={`p-2 ${
                      indexLtp > strikePrice ? "bg-sky-50" : ""
                    } group-hover:!bg-teal-200`}
                  >
                    <p>{matchedData ? matchedData.Volume_CE : ""}</p>
                  </td>
                  <td
                    className={`p-2 cursor-pointer text-white font-bold ${
                      indexLtp > strikePrice ? "bg-sky-50" : ""
                    } group-hover:!bg-teal-200`}
                  >
                    {matchedData && (
                      <span
                        style={{
                          backgroundColor: matchedData?.color_CE || "#4CBF72",
                          padding: "5%",
                          borderRadius: "100px",
                        }}
                        className="my-first-step"
                        onClick={() => onHandleLTPClick(strikePrice, "CE")}
                      >
                        {matchedData ? matchedData.LTP_CE : ""}
                      </span>
                    )}
                  </td>
                  <td
                    className={`p-2 ${
                      indexLtp > strikePrice ? "bg-sky-50" : ""
                    } group-hover:!bg-teal-200`}
                  ></td>
                  <td
                    className={`p-2 text-center ${
                      strikePrice == currentIndex
                        ? "bg-blue-700 border"
                        : "bg-sky-100"
                    } group-hover:bg-teal-200`}
                  >
                    {strikePrice}
                  </td>
                  <td
                    className={`p-2 ${
                      indexLtp < strikePrice ? "bg-sky-50" : ""
                    } group-hover:!bg-teal-200`}
                  ></td>
                  <td
                    className={`p-2  cursor-pointer text-white font-bold ${
                      indexLtp < strikePrice ? "bg-sky-50" : ""
                    } group-hover:!bg-teal-200`}
                  >
                    {matchedData && (
                      <span
                        style={{
                          backgroundColor: matchedData?.color_PE || "#4CBF72",
                          padding: "5%",
                          borderRadius: "100px",
                        }}
                        onClick={() => onHandleLTPClick(strikePrice, "PE")}
                      >
                        {matchedData ? matchedData.LTP_PE : ""}
                      </span>
                    )}
                  </td>
                  <td
                    className={`p-2 text-center ${
                      indexLtp < strikePrice ? "bg-sky-50" : ""
                    } group-hover:bg-teal-200 `}
                  >
                    {matchedData ? matchedData.Volume_PE : ""}
                  </td>
                  <td
                    className={`p-2 text-end ${
                      indexLtp < strikePrice ? "bg-sky-50" : ""
                    } group-hover:bg-teal-200`}
                  >
                    <p>{matchedData ? matchedData.OI_PE : ""}</p>
                  </td>
                </tr>
              );

              return rows;
            })}
          {isPopping && isLoggedIn && (
            <Popup
              open={isPopping}
              onClose={() => setIsPopping(false)}
              position="right center"
              className="popup-overlay"
            >
              <InstantOptionsCard
                strikeRate={strikeRate}
                type={type}
                setIsPopping={setIsPopping}
                index={currentIndex}
              />
            </Popup>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default TradeList;
