import React, { useEffect, useState } from "react";
import ApiService from "../../Services/ApiService";
import { useNavigate } from "react-router-dom";
//validator
import * as Validator from "validatorjs";

export default function Login() {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  useEffect(() => {
    //Check for login
    //Call Profile Api , if successfull take to dashboard
    window.scrollTo(0, 0);
  }, []);

  const validateForm = (fieldname) => {
    const validation = new Validator(
      {
        email: email,
        password: password,
      },
      {
        email: "required|email",
        password: "required|min:3",
      }
    );

    validation.passes();
    if (validation.fails()) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [fieldname]: validation.errors.first(fieldname),
      }));
      return false;
    }
    // Clear validation error if validation passes for the field
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldname]: "",
    }));
    return true;
  };
  const loginWithEmail = async () => {
    ApiService.postData("/auth/login", {
      uname: email,
      pass: password,
    }).then((result) => {
      if (result.success) {
        window.localStorage.setItem("userLoginToken", result.data.accessToken);
        window.localStorage.setItem(
          "userInfo",
          JSON.stringify({
            id: result.data.id,
            name: result.data.name,
          })
        );
        navigate("/manage/dashboard");
      } else {
        setError(result.message);
      }
    });
  };
  return (
    <div className="min-w-[1000px] relative hidden h-[500px] flex-col items-center justify-center md:grid">
      <div className="lg:p-8">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
          <div className="flex flex-col space-y-2 text-center">
            <h1 className="text-2xl font-semibold tracking-tight">
              Login to your account
            </h1>
            <p className="text-sm text-muted-foreground">
              Enter your email below to login to your account
            </p>
          </div>
          <div className="grid gap-6">
            <form>
              <div className="grid gap-2">
                <div className="grid gap-1">
                  <label
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 sr-only"
                    for="email"
                  >
                    Email
                  </label>
                  <input
                    onChange={(ev) => {
                      setEmail(ev.target.value);
                    }}
                    onBlur={() => validateForm("email")}
                    className="flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:cursor-not-allowed disabled:opacity-50"
                    id="email"
                    placeholder="name@example.com"
                    autocapitalize="none"
                    autocomplete="email"
                    autocorrect="off"
                    type="email"
                    value={email}
                  />
                  {validationErrors.email && (
                    <span className="mt-1 text-sm text-red-400">
                      *{validationErrors.email}
                    </span>
                  )}
                </div>
                <div className="grid gap-1">
                  <label
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 sr-only"
                    for="password"
                  >
                    Password
                  </label>
                  <input
                    onChange={(ev) => {
                      setPassword(ev.target.value);
                    }}
                    onBlur={() => validateForm("password")}
                    type="password"
                    className="flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:cursor-not-allowed disabled:opacity-50"
                    id="password"
                    placeholder="password"
                    value={password}
                  />
                  {validationErrors.password && (
                    <span className="mt-1 text-sm text-red-400">
                      *{validationErrors.password}
                    </span>
                  )}
                </div>
                <button
                  onClick={(ev) => {
                    ev.preventDefault();
                    loginWithEmail();
                  }}
                  className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground shadow hover:bg-primary/90 h-9 px-4 py-2"
                >
                  Sign In
                </button>
                {error ? (
                  <div className="w-full flex justify-center">
                    <div className="text-sm leading-relaxed text-red-500">
                      {error}
                    </div>
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
