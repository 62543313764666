import React, { useEffect, useState } from "react";
//chart
import { Chart } from "react-google-charts";
//loader
import ClipLoader from "react-spinners/ClipLoader";
//socket
import getSocket from "../../Services/API/Socket";
//moment
import moment from "moment";
import ApiService from "../../Services/ApiService";

function Graph(props) {
  const [graphData, setGraphData] = useState([["time", "OI_CE", "OI_PE"]]);
  const [loading, setLoading] = useState(true);
  const [oiChange, setOiChange] = useState([]);
  const socket = getSocket();
  const oiData = { OI_PE: null, OI_CE: null };
  const currentTime = moment().format("LT");

  useEffect(() => {
    const fetchDataAndSetGraphData = async () => {
      try {
        setLoading(true);
        const response = await ApiService.fetchData(
          `/data/fetch/oi/${props.index}/${props.strikeprice}`
        );

        if (response.success) {
          if (!props.toggleOiChange) {
            const oiChangeHistory = response?.data?.OI_History
              ? response.data.OI_History.map((final) => {
                  return [
                    final[0],
                    final[1] - response.data.OI_START["CE"],
                    final[2] - response.data.OI_START["PE"],
                  ];
                })
              : [];

            const newData = [
              ["time", "OI_CE", "OI_PE"],
              ...oiChangeHistory.reverse(),
            ];
            setGraphData(newData);
            setLoading(false);
          } else {
            const newData = [
              ["time", "OI_CE", "OI_PE"],
              ...response?.data?.OI_History?.reverse(),
            ];
            setGraphData(newData);
            setLoading(false);
          }
          setOiChange(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchDataAndSetGraphData();
  }, [props.toggleOiChange]);
  useEffect(() => {
    const handleData = (data) => {
      if (data.SEM_INSTRUMENT_NAME === "OPTIDX" && data.type === "OI Data") {
        //if doesn't need duplicate then continue only if  oiData[`OI_${data.SEM_OPTION_TYPE}`] != data.OI
        oiData[`OI_${data.SEM_OPTION_TYPE}`] = data.OI;
        if (oiData.OI_CE && oiData.OI_PE) {
          setGraphData((prevGraphData) => [
            ...prevGraphData,
            ...[[currentTime, oiData.OI_CE, oiData.OI_PE]],
          ]);
        }
        if (graphData.length > 1) {
          setLoading(false);
        }
      }
    };

    socket.on(`OPTIDX_${props.index}_${props.strikeprice}`, handleData);
    return () => {
      socket.off(`OPTIDX_${props.index}_${props.strikeprice}`, handleData);
    };
  }, [props.index, props.strikeprice, graphData.length, socket]);

  useEffect(() => {
    if (graphData.length > 150) {
      setGraphData((prevGraphData) => {
        const newData = [...prevGraphData];
        newData.splice(1, 30);
        return newData;
      });
    }
  }, [graphData]);
  const updatedOiChange = graphData.map((data, index) =>
    index > 10
      ? [
          data[0],
          data[1] - (oiChange?.OI_START?.["CE"] || 0),
          data[2] - (oiChange?.OI_START?.["PE"] || 0),
        ]
      : data
  );
  const numberToLakhs = (number) => {
    const parsedNumber = parseInt(number);
    if (isNaN(parsedNumber)) {
      return `0 L`;
    }
    return `${(parsedNumber / 100000).toFixed(3)} L`;
  };

  const lastOiChange = updatedOiChange[updatedOiChange.length - 1] || null;
  const lastVal = graphData[graphData.length - 1] || null;
  const options = {
    // explorer: { maxZoomIn: 0.5, maxZoomOut: 0.5 },
    titleTextStyle: {
      fontSize: 16,
    },
    chartArea: { width: "70%" },
    title: props.index + ": " + props.strikeprice,
    backgroundColor: lastVal
      ? lastVal[2] > lastVal[1]
        ? "#e3fae6"
        : "#fae8e3"
      : "#f7f7f8",
    hAxis: {
      title: "Time",
    },

    legend: { position: "bottom" },
    // colors: ["#18eb10", "#e6353e"],
    colors: ["#008000", "#880808"],
    suppressErrors: false,
    ErrorEvents: false,
  };

  let confidence =
    lastVal[2] > lastVal[1]
      ? parseInt(((lastVal[2] - lastVal[1]) / lastVal[1]) * 100).toFixed(0)
      : ((lastVal[1] - lastVal[2]) / lastVal[2]) * 100 >= 100.0
      ? 100
      : (((lastVal[1] - lastVal[2]) / lastVal[2]) * 100).toFixed(0);

  return (
    <div className="w-full">
      {loading ? (
        <div className="flex justify-center min-h-[300px] items-center">
          <ClipLoader
            color="blue"
            loading={loading}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        graphData && (
          <>
            {!props.toggleOiChange ? (
              <>
                <div>
                  <h3
                    className={`mid-pc:text-[14px] lg-pc:text-[18px]
                      ${
                        lastOiChange[2] > lastOiChange[1]
                          ? lastVal[2] > lastVal[1]
                            ? "bg-green-800 text-white p-3"
                            : "bg-gradient-to-r from-green-800 via-green-500 to-green-400 text-white p-3"
                          : lastVal[2] > lastVal[1]
                          ? "bg-gradient-to-r from-red-800 via-red-500 to-red-400 text-white p-3"
                          : "bg-red-800 text-white p-3"
                      }`}
                  >
                    {`OI-CHANGE: ${
                      lastOiChange[2] > lastOiChange[1] ? "BULLISH" : "BEARISH"
                    } (TOTAL-OI: ${
                      lastVal[2] > lastVal[1] ? "BULLISH" : "BEARISH"
                    })`}
                  </h3>
                  <div
                    className={
                      lastOiChange[2] > lastOiChange[1]
                        ? "bg-[#e3fae6]"
                        : "bg-[#fae8e3]"
                    }
                  >
                    <div className="flex justify-end px-3 py-1 text-sm font-bold">
                      CALL: {numberToLakhs(lastOiChange[1])}
                    </div>
                    <div className="flex justify-end px-3 text-sm font-bold">
                      PUT: {numberToLakhs(lastOiChange[2])}
                    </div>
                  </div>
                </div>

                <div className="h-[200px] mid-pc:h-[300px] lg-pc:h-[400px]">
                  <Chart
                    chartType="LineChart"
                    width="100%"
                    height="100%"
                    data={updatedOiChange}
                    options={{
                      ...options,
                      backgroundColor: lastOiChange
                        ? lastOiChange[2] > lastOiChange[1]
                          ? "#e3fae6"
                          : "#fae8e3"
                        : "#f7f7f8",
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <div>
                  <h3
                    className={`sm:text-[14px] md:text-[18px] lg:text-[18px]
                      ${
                        lastVal[2] > lastVal[1]
                          ? lastOiChange[2] > lastOiChange[1]
                            ? "bg-green-800 text-white p-3"
                            : "bg-gradient-to-r from-green-800 via-green-500 to-green-400 text-white p-3"
                          : lastOiChange[2] > lastOiChange[1]
                          ? "bg-gradient-to-r from-red-800 via-red-500 to-red-400 text-white p-3"
                          : "bg-red-800 text-white p-3"
                      }`}
                  >
                    {`TOTAL-OI: ${
                      lastVal[2] > lastVal[1] ? "BULLISH" : "BEARISH"
                    } (OI-CHANGE: ${
                      lastOiChange[2] > lastOiChange[1] ? "BULLISH" : "BEARISH"
                    })`}
                  </h3>
                  <div
                    className={
                      lastVal[2] > lastVal[1] ? "bg-[#e3fae6]" : "bg-[#fae8e3]"
                    }
                  >
                    <div className="flex justify-end px-3 py-1 text-sm font-bold">
                      CALL: {numberToLakhs(lastVal[1])}
                    </div>
                    <div className="flex justify-end px-3 text-sm font-bold">
                      PUT: {numberToLakhs(lastVal[2])}
                    </div>
                  </div>
                </div>
                <div className="h-[200px] mid-pc:h-[300px] lg-pc:h-[400px]">
                  <Chart
                    chartType="LineChart"
                    width="100%"
                    height="100%"
                    data={graphData}
                    options={options}
                  />
                </div>
              </>
            )}
          </>
        )
      )}
    </div>
  );
}

export default Graph;
