"use client";
import TradeList from "../../Pages/TradeList/TradeList";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "../../Components/ErrorPage/ErrorPage";
import TradeHead from "../../Pages/TradeHead/TradeHead";

function TradePage() {
  return (
    <div className="font-sans mt-[110px]">
      {/* <ErrorBoundary FallbackComponent={<ErrorPage />}> */}
      <TradeHead></TradeHead>
      <TradeList></TradeList>
      {/* </ErrorBoundary> */}
    </div>
  );
}

export default TradePage;
