let optionData = [];

export function UpdateData(data) {
  const {
    SEM_STRIKE_PRICE,
    SEM_EXPIRY_DATE,
    SEM_OPTION_TYPE,
    SEM_SMST_SECURITY_ID,
    SEM_CUSTOM_SYMBOL,
    SEM_LOT_UNITS,
    volume,
    OI,
    LTP,
    type,
    SEM_TRADING_SYMBOL,
  } = data;
  const index = SEM_TRADING_SYMBOL?.split("-")[0];

  const findIndex = optionData.findIndex((item) => item.index == index);

  if (findIndex === -1) {
    optionData = [];
  }
  const existingIndex = optionData.findIndex(
    (item) => item.strikePrice === SEM_STRIKE_PRICE
  );

  const newData = {
    index,
    strikePrice: SEM_STRIKE_PRICE,
    Lot_Size: SEM_LOT_UNITS,
    Expiry_Date: SEM_EXPIRY_DATE,
    Sec_Id_CE: SEM_OPTION_TYPE === "CE" ? SEM_SMST_SECURITY_ID ?? "" : "",
    Sec_Id_PE: SEM_OPTION_TYPE === "PE" ? SEM_SMST_SECURITY_ID ?? "" : "",
    Custom_Symbol_CE: SEM_OPTION_TYPE === "CE" ? SEM_CUSTOM_SYMBOL ?? "" : "",
    Custom_Symbol_PE: SEM_OPTION_TYPE === "PE" ? SEM_CUSTOM_SYMBOL ?? "" : "",
    Volume_CE: SEM_OPTION_TYPE === "CE" ? volume ?? "" : "",
    Volume_PE: SEM_OPTION_TYPE === "PE" ? volume ?? "" : "",
    OI_CE: type === "OI Data" && SEM_OPTION_TYPE === "CE" ? OI ?? "" : "",
    OI_PE: type === "OI Data" && SEM_OPTION_TYPE === "PE" ? OI ?? "" : "",
    LTP_CE: type === "Quote Data" && SEM_OPTION_TYPE === "CE" ? LTP ?? "" : "",
    LTP_PE: type === "Quote Data" && SEM_OPTION_TYPE === "PE" ? LTP ?? "" : "",
  };

  if (existingIndex === -1) {
    optionData.push(newData);
  } else {
    const existingEntry = optionData[existingIndex];
    optionData[existingIndex] = {
      ...existingEntry,
      Sec_Id_CE:
        SEM_OPTION_TYPE === "CE"
          ? SEM_SMST_SECURITY_ID ?? existingEntry.Sec_Id_CE
          : existingEntry.Sec_Id_CE,
      Sec_Id_PE:
        SEM_OPTION_TYPE === "PE"
          ? SEM_SMST_SECURITY_ID ?? existingEntry.Sec_Id_PE
          : existingEntry.Sec_Id_PE,
      Custom_Symbol_CE:
        SEM_OPTION_TYPE === "CE"
          ? SEM_CUSTOM_SYMBOL ?? existingEntry.Custom_Symbol_CE
          : existingEntry.Custom_Symbol_CE,
      Custom_Symbol_PE:
        SEM_OPTION_TYPE === "PE"
          ? SEM_CUSTOM_SYMBOL ?? existingEntry.Custom_Symbol_PE
          : existingEntry.Custom_Symbol_PE,

      Volume_CE:
        SEM_OPTION_TYPE === "CE"
          ? volume ?? existingEntry.Volume_CE
          : existingEntry.Volume_CE,
      Volume_PE:
        SEM_OPTION_TYPE === "PE"
          ? volume ?? existingEntry.Volume_PE
          : existingEntry.Volume_PE,
      OI_CE:
        type === "OI Data" && SEM_OPTION_TYPE === "CE"
          ? OI ?? existingEntry.OI_CE
          : existingEntry.OI_CE,
      OI_PE:
        type === "OI Data" && SEM_OPTION_TYPE === "PE"
          ? OI ?? existingEntry.OI_PE
          : existingEntry.OI_PE,
      LTP_CE:
        type === "Quote Data" && SEM_OPTION_TYPE === "CE"
          ? LTP ?? existingEntry.LTP_CE
          : existingEntry.LTP_CE,
      LTP_PE:
        type === "Quote Data" && SEM_OPTION_TYPE === "PE"
          ? LTP ?? existingEntry.LTP_PE
          : existingEntry.LTP_PE,
    };
  }
  return optionData;
}
