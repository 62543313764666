import { Chart } from "react-google-charts";

function GuageChart(props) {
  const getLabelMessage = (value) => {
    if (value < -0.5) {
      return "Strong Sell";
    } else if (value < 0) {
      return "Sell";
    } else if (value > 0 && value < 0.5) {
      return "Buy";
    } else if (value > 0.5) {
      return "Strong Buy";
    } else {
      return "Neutral";
    }
  };

  const summaryMessage = getLabelMessage(props.summary);
  const macdMessage = getLabelMessage(props.MACD);
  const emaMessage = getLabelMessage(props.EMA);
  const data = [
    ["Label", "Value"],
    ["", props.summary],
    ["", props.MACD],
    ["", props.EMA],
  ];

  const options = {
    redFrom: -1,
    redTo: 0,
    yellowFrom: 0,
    yellowTo: 0.1,
    greenFrom: 0.1,
    greenTo: 1,
    minorTicks: 1,
    max: 1,
    min: -1,
    angle: 0,
    textStyle: {
      color: "black",
      fontSize: 12,
    },
    minorTickTextStyle: {
      color: "transparent", // Hide default minor tick labels
    },
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between text-sm font-bold mb-2">
        <p className="flex-1 text-center">Summary</p>
        <p className="flex-1 text-center">MACD</p>
        <p className="flex-1 text-center">EMA</p>
      </div>
      <div className="flex flex-row justify-between space-x-2">
        <div className="w-[100px] h-[100px] sm:w-[80px] sm:h-[80px] md:w-[90px] md:h-[90px] lg:w-[100px] lg:h-[100px]">
          <Chart
            chartType="Gauge"
            data={[
              ["Label", "Value"],
              ["", props.summary],
            ]}
            options={{
              ...options,
              height: "100%",
              width: "100%",
              chartArea: {
                width: "100%",
                height: "100%",
              },
            }}
            rootProps={{ "data-testid": "gauge-chart-summary" }}
          />
        </div>
        <div className="w-[90px] h-[90px] sm:w-[70px] sm:h-[70px] md:w-[80px] md:h-[80px] lg:w-[90px] lg:h-[90px]  mt-2">
          <Chart
            chartType="Gauge"
            data={[
              ["Label", "Value"],
              ["", props.MACD],
            ]}
            options={{
              ...options,
              height: "100%",
              width: "100%",
              chartArea: {
                width: "100%",
                height: "100%",
              },
            }}
            rootProps={{ "data-testid": "gauge-chart-macd" }}
          />
        </div>
        <div className="w-[90px] h-[90px] sm:w-[70px] sm:h-[70px] md:w-[80px] md:h-[80px] lg:w-[90px] lg:h-[90px] mt-2">
          <Chart
            chartType="Gauge"
            data={[
              ["Label", "Value"],
              ["", props.EMA],
            ]}
            options={{
              ...options,
              height: "100%",
              width: "100%",
              chartArea: {
                width: "100%",
                height: "100%",
              },
            }}
            rootProps={{ "data-testid": "gauge-chart-ema" }}
          />
        </div>
      </div>

      <div className="font-semibold flex mt-2">
        <div
          className={`flex-1 text-sm text-center ${
            props.summary > 0
              ? "text-green-600"
              : props.summary < 0
              ? "text-red-600"
              : "text-yellow-600"
          }`}
        >
          {summaryMessage}
        </div>
        <div
          className={`flex-1 text-sm text-center ${
            props.MACD > 0
              ? "text-green-600"
              : props.MACD < 0
              ? "text-red-600"
              : "text-yellow-600"
          }`}
        >
          {macdMessage}
        </div>
        <div
          className={`flex-1 text-sm text-center ${
            props.EMA > 0
              ? "text-green-600"
              : props.EMA < 0
              ? "text-red-600"
              : "text-yellow-600"
          }`}
        >
          {emaMessage}
        </div>
      </div>
    </div>
  );
}

export default GuageChart;
