import Graph from "../../Components/Graph/Graph";
import { useEffect, useRef, useState } from "react";
//api
import ApiService from "../../Services/ApiService";
//ui
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../Components/ui/select";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../Components/ui/dialog";
import { Tabs, TabsList, TabsTrigger } from "../../Components/ui/tabs";
import { GrClose } from "react-icons/gr";
import { FaArrowAltCircleUp } from "react-icons/fa";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { GoScreenFull } from "react-icons/go";
//socket
import getSocket from "../../Services/API/Socket";
import { toast } from "sonner";
import { useNavigate, useLocation, useParams } from "react-router";
import GuageChart from "../../Components/GuageChart/GuageChart";
import { useDispatch, useSelector } from "react-redux";
import {
  setHideHeader,
  setSocketTimeStamp,
} from "src/Container/Redux/Slices/DataSlice";

import Joyride, { STATUS } from "react-joyride";

import { Tooltip } from "react-tooltip";
function LineChartDisplay({ notLoggedIn, margin }) {
  const { selectedIndex } = useParams();
  margin = margin === undefined ? false : margin;
  notLoggedIn = notLoggedIn === undefined ? false : notLoggedIn;
  const [indexKeys, setIndexKeys] = useState([]);
  const [index, setIndex] = useState(selectedIndex || null);
  const [selectedStrikePrices, setSelectedStrikePrices] = useState([]);
  const [strikePrices, setStrikePrices] = useState([]);
  const [indexLtp, setIndexLtp] = useState("");
  const socket = getSocket();
  const [lastPrice, setLastPrice] = useState("");
  const now = new Date();
  const navigate = useNavigate();
  const [isFixed, setIsFixed] = useState(false);
  const [toggleOiChange, setToggleOiChange] = useState(false);
  const [summaryTime, setSummaryTime] = useState("1_min");
  const [summaryData, setSummaryData] = useState([]);
  const [summaryValue, setSummaryValue] = useState(0);
  const [EMA, setEMA] = useState(0);
  const [MACD, setMACD] = useState(0);
  const isHideHeader = useSelector((state) => state.tradeData.isHideHeader);
  const [plSummary, setPlSummary] = useState(0);
  const dispatch = useDispatch();
  const { indexname } = useParams();
  // Define the target times
  const startTime = new Date();
  startTime.setHours(0); // 9:15 AM
  startTime.setMinutes(15);
  startTime.setSeconds(0);
  const endTime = new Date();
  endTime.setHours(23); //3:30 PM
  endTime.setMinutes(59);
  endTime.setSeconds(0);
  const steps = [
    {
      target: ".my-first-step",
      content:
        "This area shows the trend based on multiple integrates and a summary value.",
      disableBeacon: true,
    },
    {
      target: ".my-second-step",
      content: "Switch between 1 min and 5 min for the indicators.",
    },
    {
      target: ".my-fourth-step",
      content:
        "if switched on the graphs will show tital oi instead of change in oi for the day.",
    },
    {
      target: ".my-sixth-step",
      content:
        "Pressing up arrow will add one more strike price to the beginning of the graphs  and down will add one to the end ",
    },
    {
      target: ".my-seventh-step",
      content: "Hide top menu . Go full screen",
    },
  ];

  useEffect(() => {
    const handleSocketData = (data) => {
      if (data.LTP) {
        dispatch(setSocketTimeStamp(Date.now()));
        setIndexLtp(data.LTP);
        const profitLoss = isNaN(data?.close - data?.open)
          ? 0
          : (data?.close || 0) - (data?.open || 0);

        setPlSummary((prevState) => {
          return profitLoss === 0 ? prevState : profitLoss;
        });
      }
    };
    const eventName = `IDX_${index}`;

    socket.on(eventName, handleSocketData);
    return () => {
      socket.off(eventName, handleSocketData);
      setIndexLtp("");
    };
  }, [socket, index, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    ApiService.fetchData("/data/fetch/indexes")
      .then((data) => {
        const indexes = data.data.indexes;
        const firstIndex = indexes[0];
        if (indexKeys.length === 0) {
          setIndexKeys(indexes.map((key) => key.SEM_TRADING_SYMBOL));
        }

        if (index === null) {
          setIndex(indexname ? indexname : firstIndex.SEM_TRADING_SYMBOL);
          const firstStrikePrice = indexname
            ? indexes
                .filter((key) => key.SEM_TRADING_SYMBOL === indexname)
                .map((data) => data.ACTIVE_STRIKES)
            : indexes
                .filter(
                  (key) =>
                    key.SEM_TRADING_SYMBOL === firstIndex.SEM_TRADING_SYMBOL
                )
                .map((data) => data.ACTIVE_STRIKES);
          const firstLtp = indexname
            ? indexes
                .filter((key) => key.SEM_TRADING_SYMBOL === indexname)
                .map((data) => data.LTP)
            : firstIndex.LTP;
          onStartGraph(firstLtp, firstStrikePrice);
          setLastPrice(firstLtp);
          setStrikePrices(firstStrikePrice);
        } else {
          const currentStrikePrices = indexes
            .filter((key) => key.SEM_TRADING_SYMBOL === index)
            .map((data) => data.ACTIVE_STRIKES);
          const lastLTP = indexes
            .filter((key) => key.SEM_TRADING_SYMBOL === index)
            .map((data) => data.LTP);
          onStartGraph(lastLTP, currentStrikePrices);
          setLastPrice(
            indexes
              .filter((key) => key.SEM_TRADING_SYMBOL === index)
              .map((data) => data.LTP)
          );
          setStrikePrices(currentStrikePrices);
        }
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
  }, [index]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };
    const handleKeyPress = (event) => {
      const key = event.key;
      if (!isNaN(key) && (key === "1" || key === "5")) {
        setSummaryTime(`${key}_min`);
      }
      if (event.shiftKey && event.key === "ArrowUp") {
        onClickUpArrow();
      }
      if (event.shiftKey && event.key === "ArrowDown") {
        onClickDownArrow();
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [strikePrices, selectedStrikePrices]);

  const handleIndex = (index) => {
    setIndex(index);
    if (notLoggedIn) {
      navigate(`/oi-analysis/${index}`);
    } else {
      navigate(`/manage/oi-analysis/${index}`);
    }

    if (selectedStrikePrices) {
      setSelectedStrikePrices([]);
    }
  };

  const fetchData = () => {
    ApiService.fetchData("/data/fetch/summary/indexes")
      .then((data) => {
        const indexes = data.data.indexes;
        if (indexes && indexes?.[index] && indexes?.[index]?.[summaryTime]) {
          if (summaryTime === "1_min") {
            setSummaryValue(indexes[index][summaryTime]["Recommend.All|1"]);
            setEMA(indexes[index][summaryTime]["Recommend.MA|1"]);
            setMACD(indexes[index][summaryTime]["Recommend.Other|1"]);
          } else {
            setSummaryValue(indexes[index][summaryTime]["Recommend.All|5"]);
            setEMA(indexes[index][summaryTime]["Recommend.MA|5"]);
            setMACD(indexes[index][summaryTime]["Recommend.Other|5"]);
          }
          setSummaryData(indexes[index][summaryTime]);
        }
      })
      .catch((error) => {
        console.error("API call failed:", error);
      });
  };

  useEffect(() => {
    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 60000);

    return () => {
      clearInterval(intervalId);
      setSummaryData([]);
      setSummaryValue(0);
      setEMA(0);
      setMACD(0);
    };
  }, [index, summaryTime]);

  const onStartGraph = (ltp, strikes) => {
    const nearestLtp = findNearestValue(parseInt(ltp), strikes[0]);
    const ltpIndex = strikes[0].indexOf(nearestLtp);
    for (let i = ltpIndex; i > ltpIndex - 3; i--) {
      if (!selectedStrikePrices.includes(strikes[0][i])) {
        setSelectedStrikePrices((prevPrice) => [strikes[0][i], ...prevPrice]);
      }
    }
    for (let i = ltpIndex + 1; i <= ltpIndex + 3; i++) {
      if (!selectedStrikePrices.includes(strikes[0][i])) {
        setSelectedStrikePrices((prevPrice) => [...prevPrice, strikes[0][i]]);
      }
    }
  };

  function findNearestValue(number, arr) {
    // Initialize variables to store the nearest value and its difference
    let nearestValue = arr[0];
    let minDifference = Math.abs(number - arr[0]);

    // Iterate through the array to find the nearest value
    arr.forEach((value) => {
      let difference = Math.abs(number - value);
      if (difference < minDifference) {
        minDifference = difference;
        nearestValue = value;
      }
    });

    return nearestValue;
  }

  const onClickUpArrow = () => {
    const ltpIndex = strikePrices[0]?.indexOf(selectedStrikePrices[0]);
    if (ltpIndex > 0) {
      const newStrikePrice = strikePrices[0][ltpIndex - 1];
      setSelectedStrikePrices((prevPrice) => [newStrikePrice, ...prevPrice]);
      toast.success(`New Strike price ${newStrikePrice} added.`);
    } else {
      toast.error("It has reached the minimum strikeprice");
    }
    // const chartElement = document
    //   .querySelector(`[aria-label="${strikePrices[0][ltpIndex]}"]`)
    //   .scrollIntoView({
    //     behavior: "smooth",
    //     block: "end",
    //     inline: "nearest",
    //   });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const onClickDownArrow = () => {
    const ltpIndex = strikePrices[0]?.indexOf(
      selectedStrikePrices[selectedStrikePrices.length - 1]
    );
    const newStrikePrice = strikePrices[0]?.[ltpIndex + 1];

    if (ltpIndex < strikePrices[0]?.length - 1) {
      setSelectedStrikePrices((prevPrice) => [...prevPrice, newStrikePrice]);
      toast.success(`New Strike price ${newStrikePrice} added.`);
    } else {
      toast.error("It has reached the maximum strikeprice");
    }
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const onClickDelete = (strikeprice) => {
    setSelectedStrikePrices(
      selectedStrikePrices.filter((data) => data !== strikeprice)
    );
  };

  const handleJoyrideCallback = (data) => {
    if (data.status === STATUS.FINISHED || data.status === STATUS.SKIPPED) {
      localStorage.setItem("run", false);
    }
  };

  return (
    <div className="flex flex-col w-100">
      {!localStorage["run"] && (
        <Joyride
          steps={steps}
          continuous="true"
          callback={handleJoyrideCallback}
          disableCloseOnEsc="true"
          scrollToFirstStep
          scrollOffset="100"
          showSkipButton
          showProgress
          styles={{
            options: {
              arrowColor: "#fff",
              backgroundColor: "#fff",
              overlayColor: "rgba(0, 0, 0, 0.5)",
              primaryColor: "#f04",
              spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
              textColor: "#333",
              zIndex: 50,
            },
            buttonSkip: {
              background: "#f04",
              borderRadius: 5,
              color: "#fff",
            },
          }}
        />
      )}

      <div
        className={`z-[49] bg-white block mid-pc:block lg-pc:fixed ${
          notLoggedIn
            ? isHideHeader
              ? "mt-0"
              : "mt-[130px] md:mt-[100px]"
            : ""
        }`}
        style={{
          top: isFixed ? 0 : "auto",
          paddingTop:
            notLoggedIn && isFixed ? (isHideHeader ? "10px" : "20px") : 0,
          transition: "top 0.7s ease, padding-top 0.7s ease",
          width: "-webkit-fill-available",
        }}
      >
        <div className="flex flex-col md:flex-row p-3 justify-between border border-teal-400 shadow-lg rounded-lg mb-3 gap-3 md:gap-0">
          <div className="flex flex-col gap-2 h-fit">
            <div className="flex gap-3">
              <div className="flex items-center">
                <Select onValueChange={handleIndex}>
                  <SelectTrigger className="w-[180px]">
                    <SelectValue defaultValue={index} placeholder={index} />
                  </SelectTrigger>
                  <SelectContent className="z-[1001]">
                    {indexKeys.map((data) => (
                      <SelectItem
                        key={data}
                        value={data}
                        className="cursor-pointer p-3 z-10 hover:bg-sky-100"
                      >
                        {data}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              {index && (
                <div
                  className={`flex flex-col justify-center font-bold px-10 text-white rounded-full ${
                    plSummary === 0
                      ? "bg-blue-500"
                      : plSummary > 0
                      ? "bg-green-500"
                      : "bg-red-500"
                  }`}
                >
                  {indexLtp ? indexLtp : lastPrice}
                </div>
              )}
            </div>
            <div className="p-2 flex flex-col gap-4 w-full md:w-[400px]">
              <div className="font-bold mid-pc:text-sm">PIVOTS</div>
              <div className="flex flex-row justify-between text-orange-500 mid-pc:text-sm">
                <div>
                  <span className="font-bold ">R1: </span>
                  {summaryTime === "1_min"
                    ? summaryData?.["Pivot.M.Classic.R1|1"] !== undefined &&
                      !isNaN(Number(summaryData["Pivot.M.Classic.R1|1"]))
                      ? Number(summaryData["Pivot.M.Classic.R1|1"]).toFixed(2)
                      : "0"
                    : summaryData?.["Pivot.M.Classic.R1|5"] !== undefined &&
                      !isNaN(Number(summaryData["Pivot.M.Classic.R1|5"]))
                    ? Number(summaryData["Pivot.M.Classic.R1|5"]).toFixed(2)
                    : "0"}
                </div>

                <div>
                  <span className="font-bold">R2: </span>
                  {summaryTime === "1_min"
                    ? summaryData?.["Pivot.M.Classic.R2|1"] !== undefined &&
                      !isNaN(Number(summaryData["Pivot.M.Classic.R2|1"]))
                      ? Number(summaryData["Pivot.M.Classic.R2|1"]).toFixed(2)
                      : "0"
                    : summaryData?.["Pivot.M.Classic.R2|5"] !== undefined &&
                      !isNaN(Number(summaryData["Pivot.M.Classic.R2|5"]))
                    ? Number(summaryData["Pivot.M.Classic.R2|5"]).toFixed(2)
                    : "0"}
                </div>

                <div>
                  <span className="font-bold">R3: </span>
                  {summaryTime === "1_min"
                    ? summaryData?.["Pivot.M.Classic.R3|1"] !== undefined &&
                      !isNaN(Number(summaryData["Pivot.M.Classic.R3|1"]))
                      ? Number(summaryData["Pivot.M.Classic.R3|1"]).toFixed(2)
                      : "0"
                    : summaryData?.["Pivot.M.Classic.R3|5"] !== undefined &&
                      !isNaN(Number(summaryData["Pivot.M.Classic.R3|5"]))
                    ? Number(summaryData["Pivot.M.Classic.R3|5"]).toFixed(2)
                    : "0"}
                </div>
              </div>

              <div className="text-blue-800 font-bold mid-pc:text-sm">
                PIVOT:&nbsp;
                <span>
                  {summaryTime === "1_min"
                    ? summaryData?.["Pivot.M.Classic.Middle|1"] !== undefined &&
                      !isNaN(Number(summaryData["Pivot.M.Classic.Middle|1"]))
                      ? Number(summaryData["Pivot.M.Classic.Middle|1"]).toFixed(
                          2
                        )
                      : "0"
                    : summaryData?.["Pivot.M.Classic.Middle|5"] !== undefined &&
                      !isNaN(Number(summaryData["Pivot.M.Classic.Middle|5"]))
                    ? Number(summaryData["Pivot.M.Classic.Middle|5"]).toFixed(2)
                    : "0"}
                </span>
              </div>
              <div className="flex flex-row justify-between text-red-600 mid-pc:text-sm">
                <div>
                  <span className="font-bold">S1: </span>
                  {summaryTime === "1_min"
                    ? isNaN(
                        summaryData &&
                          Number(summaryData["Pivot.M.Classic.S1|1"])
                      )
                      ? 0
                      : Number(
                          summaryData && summaryData["Pivot.M.Classic.S1|1"]
                        ).toFixed(2)
                    : isNaN(
                        Number(
                          summaryData && summaryData["Pivot.M.Classic.S1|5"]
                        )
                      )
                    ? 0
                    : Number(
                        summaryData && summaryData["Pivot.M.Classic.S1|5"]
                      ).toFixed(2)}
                </div>
                <div>
                  <span className="font-bold">S2: </span>
                  {summaryTime === "1_min"
                    ? isNaN(
                        Number(
                          summaryData && summaryData["Pivot.M.Classic.S2|1"]
                        )
                      )
                      ? 0
                      : Number(
                          summaryData && summaryData["Pivot.M.Classic.S2|1"]
                        ).toFixed(2)
                    : isNaN(
                        Number(
                          summaryData && summaryData["Pivot.M.Classic.S2|5"]
                        )
                      )
                    ? 0
                    : Number(
                        summaryData && summaryData["Pivot.M.Classic.S2|5"]
                      ).toFixed(2)}
                </div>
                <div>
                  <span className="font-bold">S3: </span>
                  {summaryTime === "1_min"
                    ? isNaN(
                        Number(
                          summaryData && summaryData["Pivot.M.Classic.S3|1"]
                        )
                      )
                      ? 0
                      : Number(
                          summaryData && summaryData["Pivot.M.Classic.S3|1"]
                        ).toFixed(2)
                    : isNaN(
                        Number(
                          summaryData && summaryData["Pivot.M.Classic.S3|5"]
                        )
                      )
                    ? 0
                    : Number(
                        summaryData && summaryData["Pivot.M.Classic.S3|5"]
                      ).toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col border rounded-md shadow-md w-fit p-2 gap-1 my-first-step ">
            <div className="my-second-step">
              <Tabs
                defaultValue="1_min"
                value={summaryTime}
                onValueChange={setSummaryTime}
              >
                <TabsList>
                  <TabsTrigger value="1_min">1 Min</TabsTrigger>
                  <TabsTrigger value="5_min">5 Min</TabsTrigger>
                </TabsList>
              </Tabs>
            </div>
            <div>
              <GuageChart
                summary={Number(summaryValue)}
                MACD={Number(MACD)}
                EMA={Number(EMA)}
              ></GuageChart>
            </div>
          </div>

          <div className="flex justify-between gap-2 md:gap-5 h-fit my-third-step">
            {!toggleOiChange ? (
              <p className="text-gray-400">Show OI</p>
            ) : (
              <p className="text-gray-400">Show OI Change</p>
            )}

            <div className="flex my-fourth-step">
              <label className="inline-flex me-5 cursor-pointer m-auto">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  onChange={() => setToggleOiChange(!toggleOiChange)}
                  checked={toggleOiChange}
                />
                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
              </label>
            </div>
            <div className="my-fifth-step">
              <Tooltip
                id="info-tooltip"
                content="Info"
                place="bottom-end"
                style={{
                  backgroundColor: "rgb(243 244 246)",
                  color: "#222",
                }}
              />
              <Dialog>
                <DialogTrigger asChild>
                  <button variant="outline">
                    {" "}
                    <IoIosInformationCircleOutline
                      size={30}
                      color="gray"
                      data-tooltip-id="info-tooltip"
                      className="focus:outline-none"
                    />
                  </button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-md z-[1002]">
                  <DialogHeader>
                    <DialogTitle className="text-lg font-sans">
                      Information
                    </DialogTitle>
                    <DialogDescription>
                      <ul className="flex flex-col gap-2 text-black text-left text-lg list-disc pl-3">
                        <li>
                          Turning on Show OI will show the OI flow in the graphs
                          with total OI otherwise with change of OI.
                        </li>
                        <li>
                          Clicking the Shift + Up button will add one more
                          strike price to the beginning.
                        </li>
                        <li>
                          Clicking the Shift + Down button will add one more
                          strike price to the end.
                        </li>
                      </ul>
                    </DialogDescription>
                  </DialogHeader>
                  <div className="flex items-center space-x-2"></div>
                  <DialogFooter className="flex m-auto border border-gray-300 rounded-lg px-2 py-1 text-white bg-black">
                    <DialogClose asChild>
                      <button type="button" className="font-bold">
                        Close
                      </button>
                    </DialogClose>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            </div>
            {now > startTime && now < endTime ? (
              <div className="flex gap-3 my-sixth-step">
                <div className="cursor-pointer" onClick={onClickUpArrow}>
                  <Tooltip
                    id="up-tooltip"
                    content="Prev Price"
                    place="bottom"
                    style={{
                      backgroundColor: "rgb(243 244 246)",
                      color: "#222",
                    }}
                  />
                  <FaArrowAltCircleUp
                    size={30}
                    color="green"
                    data-tooltip-id="up-tooltip"
                    className="focus:outline-none"
                  />
                </div>

                <div className="cursor-pointer" onClick={onClickDownArrow}>
                  <Tooltip
                    id="down-tooltip"
                    content="Next Price"
                    place="bottom"
                    style={{
                      backgroundColor: "rgb(243 244 246)",
                      color: "#222",
                    }}
                  />
                  <FaArrowAltCircleDown
                    size={30}
                    color="red"
                    data-tooltip-id="down-tooltip"
                    className="focus:outline-none"
                  />
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {notLoggedIn && (
              <div className="cursor-pointer my-seventh-step ">
                <Tooltip
                  id="screen-tooltip"
                  content="Heading Toggle"
                  place="bottom-end"
                  style={{
                    backgroundColor: "rgb(243 244 246)",
                    color: "#222",
                  }}
                />
                <GoScreenFull
                  size={30}
                  color="gray"
                  onClick={() => dispatch(setHideHeader(true))}
                  data-tooltip-id="screen-tooltip"
                  className="focus:outline-none"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={`flex flex-row sm:flex-col flex-wrap ${
          notLoggedIn
            ? isHideHeader
              ? "mt-[0px] lg-pc:mt-[270px]"
              : "mt-[0px] lg-pc:mt-[360px]"
            : "lg-pc:mt-[270px] mt-[0px]"
        }`}
      >
        {now > startTime && now < endTime ? (
          selectedStrikePrices.map((strikePrice) => (
            <div
              key={strikePrice}
              className="flex flex-col md:flex-row w-full md:w-1/3 border border-teal-400 relative shadow-lg rounded-lg"
              aria-label={strikePrice}
            >
              <div className="flex absolute top-3 right-3 p-2 z-10">
                <GrClose
                  className="cursor-pointer"
                  onClick={() => onClickDelete(strikePrice)}
                  color="white"
                />
              </div>
              <div className="p-2 w-full" id="graph-data">
                <Graph
                  index={index}
                  strikeprice={strikePrice}
                  toggleOiChange={toggleOiChange}
                ></Graph>
              </div>
            </div>
          ))
        ) : (
          <div className="flex justify-center items-center mx-auto">
            <p>
              OI Analysis will work only during the MarketTrading hours (
              09:15AM to 03:30 PM )
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default LineChartDisplay;
