import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaChevronRight } from "react-icons/fa6";
import ApiService from "../../../Services/ApiService";

export default function SocketControl() {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    // ApiService.fetchData("/manager/users/list/settings").then((response) => {
    //   if (response.success) {
    //     setUsers(response.data);
    //   }
    // });
  }, []);

  const handleConnect = () => {
    ApiService.fetchData("/source/start").then((response) => {
      alert("Connected");
    });
  };
  const handleDisconnect = () => {
    ApiService.fetchData("/source/disconnect").then((response) => {
      alert("Disconnected");
    });
  };
  const handleSubscribe = () => {
    ApiService.postData("/source/subscribe").then((response) => {
      alert("Subscribed");
    });
  };

  return (
    <div className="container-fluid px-[0.625rem]">
      <div className="grid grid-cols-1 pb-6">
        <div className="md:flex items-center justify-between px-[2px]">
          <h4 className="text-[18px] font-medium text-gray-800 mb-sm-0 grow dark:text-gray-100 mb-2 md:mb-0">
            SocketControl
          </h4>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-6 gap-y-0 2xl:gap-6 md:grid-cols-2 2xl:grid-cols-4">
        <div>
          <h3>Connect to data provider</h3>
          <button
            onClick={handleConnect}
            className="mt-2 py-1 px-1 text-white bg-green-500 text-sm rounded shadow shadow-violet-100 dark:shadow-gray-900"
          >
            Connect
          </button>
        </div>
        <div>
          <h3>Disconnect from data provider</h3>
          <button
            onClick={handleDisconnect}
            className="mt-2 py-1 px-1 text-white bg-red-500 text-sm rounded shadow shadow-violet-100 dark:shadow-gray-900"
          >
            Disconnect
          </button>
        </div>
        <div>
          <h3>Subscribe to all option chain for immediate expiries</h3>
          <button
            onClick={handleSubscribe}
            className="mt-2 py-1 px-1 text-white bg-orange-500 text-sm rounded shadow shadow-violet-100 dark:shadow-gray-900"
          >
            Subscribe
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-6 gap-y-0 2xl:gap-6 md:grid-cols-2 2xl:grid-cols-4">
        <div>
          <h3>Provider Token</h3>
        </div>
      </div>
    </div>
  );
}
