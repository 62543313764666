import { useEffect, useRef } from "react";
import getSocket from "../../Services/API/Socket";
import { toast } from "sonner";
import moment from "moment";
import TradeSuggestion from "../TradeSuggestion/TradeSuggestion";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationTrigger } from "src/Container/Redux/Slices/DataSlice";

function MessageAlert() {
  return;
  const socket = getSocket();
  const dispatch = useDispatch();
  const inactivityThreshold = 10000; // 10 seconds
  const socketTimeStamp = useSelector(
    (state) => state.tradeData.socketTimeStamp
  );

  const lastSocketTimeStampRef = useRef(socketTimeStamp);

  const checkInactivity = () => {
    const now = Date.now();

    const lastSocketTimeStamp = lastSocketTimeStampRef.current;
    // console.log(now - lastSocketTimeStamp);
    // Check if enough time has passed since the last check
    if (now - lastSocketTimeStamp > inactivityThreshold) {
      toast.warning(
        `Caution: Exchange data is delayed, last data received at ${new Date(
          lastSocketTimeStamp
        ).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })}`,
        {
          position: "top-center",
          unstyle: true,
          duration: 5000,
        }
      );
    }
  };

  useEffect(() => {
    lastSocketTimeStampRef.current = socketTimeStamp;
  }, [socketTimeStamp]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      checkInactivity();
    }, 10000);
    return () => {
      clearInterval(intervalId); // Clear the interval when component unmounts
    };
  }, []);
  useEffect(() => {
    const message = {
      display_type: "toast",
      type: "warning",
      message_text: "You need to login for more user experience",
      target: {
        type: "redirect",
        url: "/login",
      },
    };

    function isHTMLString(str) {
      const htmlRegex = /<[^>]+>/g;
      return htmlRegex.test(str);
    }

    const setTradeSuggestion = (data) => {
      let updatedBuyData = [];
      if (!localStorage["trade_suggestion"]) {
        const sugData = {
          display_type: data.display_type,
          type: data.type,
          message_text: data.message_text,
          target: data.target,
          unread: true,
          isHTML: isHTMLString(data.message_text),
          timestamp: moment().calendar(),
        };
        updatedBuyData = [sugData];
      } else {
        updatedBuyData = JSON.parse(localStorage["trade_suggestion"]);
        const sugData = {
          display_type: data.display_type,
          type: data.type,
          message_text: data.message_text,
          target: data.target,
          unread: true,
          isHTML: isHTMLString(data.message_text),
          timestamp: moment().calendar(),
        };
        updatedBuyData.unshift(sugData);
      }
      localStorage.setItem("trade_suggestion", JSON.stringify(updatedBuyData));
      dispatch(setNotificationTrigger(true));
    };

    const handleMessageAlert = (data) => {
      if (!socket) {
        return;
      }
      if (data.display_type === "trade_suggestion") {
        setTradeSuggestion(data);
      }
      if (data.display_type === "toast" && toast?.[data.type]) {
        toast?.[data.type](data.message_text, {
          position: "top-center",
          unstyle: true,
          duration: 3000,
        });
      }
    };

    socket.on("MSGDATA", handleMessageAlert);

    // Cleanup function
    return () => {
      socket.off("MSGDATA", handleMessageAlert);
    };
  }, [socket]);

  return (
    <div>
      <TradeSuggestion></TradeSuggestion>
    </div>
  );
}

export default MessageAlert;
