import { useEffect, useState } from "react";
//table
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../Components/ui/table";
//api
import ApiService from "src/Container/Services/ApiService";
//loader
import { ClipLoader } from "react-spinners";

function PivotTable() {
  const [stockPivotData, setStockPivotData] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
    ApiService.fetchData("/data/fetch/stock-screeners")
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.stocks &&
          response.data.data.stocks.screener
        ) {
          setStockPivotData(response.data.data.stocks.screener);
        } else {
          console.warn("Unexpected response structure:", response);
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }, []);

  return (
    <div className="flex flex-col pt-[100px] px-2 ">
      <div className="flex flex-col gap-2">
        <div>
          <p className="text-gray-800">{stockPivotData?.index}</p>
        </div>
        {stockPivotData?.stocks?.map((item, i) => (
          <div className="flex flex-col md:flex-row gap-3 justify-between}">
            {Object.entries(item).map(([key, value]) => (
              <div
                className={`flex flex-col w-100 rounded p-2 text-sm ${
                  key === "S3"
                    ? "bg-red-200"
                    : key === "S2"
                    ? "bg-red-100"
                    : key === "S1"
                    ? "bg-red-50"
                    : key === "Pivot"
                    ? "bg-gray-100"
                    : key === "R1"
                    ? "bg-green-50"
                    : key === "R2"
                    ? "bg-green-100"
                    : "bg-green-200"
                }`}
              >
                <div
                  key={key}
                  className={`${
                    key === "S3"
                      ? "text-red-500"
                      : key === "S2"
                      ? "text-red-500"
                      : key === "S1"
                      ? "text-red-500"
                      : key === "Pivot"
                      ? "text-gray-800"
                      : key === "R1"
                      ? "text-green-500"
                      : key === "R2"
                      ? "text-green-500"
                      : "text-green-500"
                  }`}
                >
                  {key}
                </div>
                <div className="text-gray-800">{value} Stocks</div>
              </div>
            ))}
          </div>
        ))}
        <div className="text-sm mt-3 text-gray-800">
          Pivot: Stocks breaking pivot levels
        </div>
        <div>
          {stockPivotData ? (
            <Table>
              <TableHeader className="bg-orange-50 border !border-orange-200">
                <TableRow className="!border-b-orange-200">
                  {stockPivotData &&
                    Object.keys(stockPivotData?.pivot?.[0]).map((key) => (
                      <TableHead
                        key={key}
                        className={`text-gray-800 ${
                          key === "Pivot" ? "bg-orange-200" : ""
                        }`}
                      >
                        {key}
                      </TableHead>
                    ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {stockPivotData?.pivot?.map((item, i) => (
                  <TableRow key={i} className="text-gray-800">
                    {Object.entries(item).map(([key, value], index) => (
                      <TableCell
                        key={index}
                        className={`${key === "Pivot" ? "bg-orange-200" : ""} ${
                          key === "Change"
                            ? value >= 0
                              ? "text-green-500"
                              : "text-red-500"
                            : key === "Change %"
                            ? value >= 0
                              ? "text-green-500"
                              : "text-red-500"
                            : ""
                        }`}
                      >
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}{" "}
              </TableBody>
            </Table>
          ) : (
            <div className="flex justify-center min-h-[300px] pt-[100px] items-center">
              <ClipLoader
                color="blue"
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default PivotTable;
