import { io } from "socket.io-client";
const socket = io.connect("http://profitolio.com:8080/");
// const socket = io.connect("http://localhost:8080/");
let isConnected = false;

socket.on("connect", () => {
  console.log("connected");
  isConnected = true;
});

const getSocket = () => {
  return socket;
};

export default getSocket;
