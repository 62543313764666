import axios from "axios";
// const BASE_URL = process.env.API_BASE_URL || "http://localhost:4009";
const BASE_URL = process.env.API_BASE_URL || "http://www.profitolio.com";
axios.defaults.baseURL = BASE_URL;

const requestWithoutHandler = (config) => {
  return new Promise((resolve, reject) => {
    axios(config)
      .then((success) => {
        resolve(success.data);
      })
      .catch((error) => {
        //handleError(error)
        reject(error);
      });
  });
};

const request = (config) => {
  return new Promise((resolve, reject) => {
    axios(config)
      .then((success) => {
        if (success.data.meta) {
        }

        resolve(success.data);
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
};

const handleError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // console.log(error.response.data);
    // console.log(error.response.status);
    if (error.response.status === 404) {
      console.log("something went wrong");
    }
    if (error.response.status === 401) {
      console.log("identified an unauthorised request , taking to login");
    }
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log("Error", error.message);
  }
};

const fetchData = (url) => {
  return request({
    method: "GET",
    headers: { accessToken: window.localStorage.getItem("userLoginToken") },
    url: BASE_URL + url,
    data: {},
  });
};

const postData = (url, data) => {
  return request({
    method: "POST",
    headers: { accessToken: window.localStorage.getItem("userLoginToken") },
    url: BASE_URL + url,
    data: data,
  });
};

const ApiService = {
  request: request,
  fetchData: fetchData,
  postData: postData,
};
export default ApiService;
