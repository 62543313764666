import React, { useEffect, useState } from "react";
import ApiService from "../../Services/ApiService";
import { useNavigate } from "react-router-dom";
import NumberView from "./NumberView";
export default function UserCard({ user }) {
  const [Token, setToken] = useState(null);
  const [message, setMessage] = useState(null);
  const [orders, setOrders] = useState({});
  const [positions, setPositions] = useState({});
  const navigate = useNavigate();
  const updateToken = async () => {
    ApiService.postData("/manager/users/update-token", {
      id: user.id,
      accessToken: Token,
    }).then((token) => {
      setMessage("Updated Successfully");

      alert("Saved");
    });
  };
  useEffect(() => {
    const ordersTemp = {};
    user.orders.map((order) => {
      ordersTemp[order.orderStatus] = (ordersTemp[order.orderStatus] || 0) + 1;
    });
    const positionsTemp = { OPEN: 0, CLOSED: 0 };
    user.positions.map((position) => {
      if (position.positionType == "CLOSED") {
        positionsTemp.CLOSED++;
      } else positionsTemp.OPEN++;
    });
    setPositions(positionsTemp);
    setOrders(ordersTemp);
  }, []);
  return (
    <div className="card dark:bg-zinc-800 dark:border-zinc-600 mb-2">
      <div className="card-body">
        <div>
          <div className="grid items-center grid-cols-12 gap-6">
            <div className="col-span-6">
              <div className="text-gray-700 dark:text-zinc-100">
                {`${user.firstName} ${user.lastName}`}
              </div>

              <h4 className="my-4 font-medium text-gray-800 text-21 dark:text-gray-100">
                <span className="counter-value pl-1" data-target={user.email}>
                  {user.email}
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className="flex mt-1 flex-col">
          <span className="p-1 pl-2 bg-red-200 m-1 rounded text-sm">
            Your token is expiring on {user.expiryDate}
          </span>
          <textarea
            className="border border-gray-400"
            onChange={(ev) => setToken(ev.target.value)}
            defaultValue={
              user.trader &&
              user.trader.miscInfo &&
              user.trader.miscInfo.accessToken
                ? user.trader.miscInfo.accessToken
                : null
            }
          ></textarea>
          <button
            onClick={updateToken}
            className="mt-2 py-1 px-1 text-white bg-green-500 text-sm rounded shadow shadow-violet-100 dark:shadow-gray-900 "
          >
            Update Token
          </button>
        </div>

        <div className="flex flex-row justify-between">
          <span
            onClick={() => {
              navigate(`/orders/${user.id}`);
            }}
          >
            Orders - {user.orders.length}({orders.PENDING || 0})
          </span>
          <span
            onClick={() => {
              navigate(`/positions/${user.id}`);
            }}
          >
            Positions - {user.positions.length}({positions.OPEN || 0})
          </span>
        </div>
        {(() => {
          let open = 0,
            close = 0;
          user.positions.map((position) => {
            if (position.positionType == "CLOSED")
              close += position.realizedProfit;
            else open += position.realizedProfit;
          });
          return (
            <div className="flex flex-row w-full justify-between">
              Open <NumberView value={open} />
              Closed <NumberView value={close} />
            </div>
          );
        })()}
      </div>
    </div>
  );
}
