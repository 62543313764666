import TradeSummary from "src/Container/Components/TradeSummary/TradeSummary";
import TradeHead from "../../TradeHead/TradeHead";
import TradeList from "../../TradeList/TradeList";

function PaperTrading() {
  return (
    <div className="w-100 p-3">
      <TradeHead isLoggedIn={true} />
      <TradeSummary isLoggedIn={true}></TradeSummary>
      <TradeList isLoggedIn={true} />
    </div>
  );
}

export default PaperTrading;
