//icons
import { TbArrowBadgeRightFilled } from "react-icons/tb";
import { RiCloseLargeFill } from "react-icons/ri";
import { BsLightningFill } from "react-icons/bs";
//socket
import getSocket from "../../Services/API/Socket";
import { toast } from "sonner";
import { useEffect, useState } from "react";
//redux
import { useDispatch } from "react-redux";
import { setBuyOption } from "../../Redux/Slices/DataSlice";
function InstantOptionsCard(props) {
  const { strikeRate, type, setIsPopping, index } = props;
  const [currentData, setCurrentData] = useState({});
  const socket = getSocket();
  const [isChecked, setIsChecked] = useState(false);
  const [lot, setLot] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleData = (data) => {
      if (data.SEM_OPTION_TYPE === type && data.type === "Quote Data") {
        setCurrentData(data);
      }
    };
    socket.on(`OPTIDX_${index}_${strikeRate}`, handleData);
    return () => {
      socket.off(`OPTIDX_${index}_${strikeRate}`, handleData);
    };
  }, [socket]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const onClickInstantBuy = () => {
    if (lot > 0 && currentData.SEM_CUSTOM_SYMBOL) {
      props.setIsPopping(false);
      const calculatedTotalQuantity = lot * currentData?.SEM_LOT_UNITS;
      const calculatedTotalPrice =
        calculatedTotalQuantity *
        (type === "PE" ? currentData?.LTP : currentData?.LTP);

      const data = [
        {
          Index: index,
          Custom_Symbol: currentData?.SEM_CUSTOM_SYMBOL,
          LTP: currentData?.LTP,
          Strike_Price: strikeRate,
          Type: type,
          Lot_Size: currentData?.SEM_LOT_UNITS,
          Expiry_Date: currentData?.SEM_EXPIRY_DATE,
          Sec_Id: currentData?.SEM_SMST_SECURITY_ID,
          Total_Quantity: calculatedTotalQuantity,
          Total_Price: calculatedTotalPrice.toFixed(2),
          Status: "open",
        },
      ];
      dispatch(setBuyOption(data));
    } else {
      if (lot < 0) {
        toast.warning("Minimum Lot Size is 1.");
      }
      if (!currentData.SEM_CUSTOM_SYMBOL) {
        toast.warning("Please wait a moment while we fetch the details.");
      }
    }
  };

  return (
    <div className="p-3  bg-white shadow-2xl ">
      <div className="bg-gray-50 p-3">
        <div className="flex justify-between">
          <div className="text-gray-700 text-3xl">
            {type === "PE"
              ? currentData.SEM_CUSTOM_SYMBOL
              : currentData.SEM_CUSTOM_SYMBOL}
          </div>
          <div>
            <RiCloseLargeFill
              size={30}
              color="gray"
              onClick={() => setIsPopping(false)}
            />
          </div>
        </div>
        <div className="flex gap-[5px] mt-3">
          <div className="text-gray-800 text-4xl">
            {type === "PE" ? currentData.LTP : currentData.LTP}
          </div>
          {/* <div className="flex text-2xl items-end text-red-800">
            -71.20 (-99.93%)
            <TbArrowBadgeRightFilled
              className="rotate-[90deg] "
              color="red"
              size={30}
            />
          </div> */}
        </div>
        <div className="flex justify-end gap-2 mt-3">
          <div
            className={
              isChecked === false
                ? "text-green-700 text-xl"
                : "text-gray-500 text-xl"
            }
          >
            Buy
          </div>
          <label className="flex cursor-pointer select-none items-center">
            <div className="relative">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                className="sr-only"
              />
              <div className="h-5 w-14 rounded-full bg-[#E5E7EB] shadow-inner"></div>
              <div
                className={`dot shadow-switch-1 absolute -top-1 flex h-7 w-7 items-center justify-center rounded-full transition ${
                  isChecked ? "translate-x-full !bg-white" : "bg-white"
                }`}
                style={{
                  left: isChecked ? "0.5rem" : "0",
                  transition: "left 0.2s ease-in-out",
                }}
              >
                <span
                  className={`active h-4 w-4 rounded-full  ${
                    isChecked ? "bg-red-700" : "bg-green-700"
                  }`}
                ></span>
              </div>
            </div>
          </label>
          <div
            className={
              isChecked === true
                ? "text-red-700 text-xl"
                : "text-gray-500 text-xl"
            }
          >
            Sell
          </div>
        </div>
      </div>

      <div>
        <button className="px-10 py-3 border border-green-600 rounded-md text-xl font-bold text-green-800 mt-5">
          Market
        </button>
      </div>

      <div className="mt-10 flex gap-16">
        <div className="flex gap-3">
          <div class="relative max-w-xl">
            <input
              type="number"
              className="peer block h-full rounded border border-gray-300 px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear  dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill  placeholder-opacity-0 custom-height"
              id="exampleFormControlInput50"
              min={0}
              value={lot}
              onChange={(e) => setLot(e.target.value)}
            />
            <label
              for="exampleFormControlInput50"
              class="pointer-events-none absolute left-3 top-[-10px] mb-0 max-w-[90%]  font-bold text-gray-600"
            >
              Lot
            </label>
            <label
              for="exampleFormControlInput50"
              class="pointer-events-none absolute right-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[twe-input-state-active]:-translate-y-[0.9rem] peer-data-[twe-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-400 dark:peer-focus:text-primary font-bold text-gray-600"
            >
              Qty:
              {lot * currentData.SEM_LOT_UNITS}
            </label>
          </div>
          <div class="relative max-w-xl">
            <input
              type="number"
              class="peer h-full block rounded border border-gray-300 px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear  motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill "
              id="exampleFormControlInput50"
              value={type === "PE" ? currentData.LTP : currentData.LTP}
            />
            <label
              for="exampleFormControlInput50"
              class="pointer-events-none absolute left-3 top-[-10px] mb-0 max-w-[90%]  font-bold text-gray-600"
            >
              Price
            </label>
          </div>
        </div>
        <div>
          {!isChecked ? (
            <button
              className="flex px-10 py-3 rounded-md text-xl font-bold bg-gradient-to-t from-lime-600 to-lime-400 text-white"
              onClick={onClickInstantBuy}
            >
              <div>
                <BsLightningFill size={30} />
              </div>
              Instant Buy
            </button>
          ) : (
            <button className="flex px-10 py-3 rounded-md text-xl font-bold bg-gradient-to-t from-red-700 to-red-500 text-white">
              <div>
                <BsLightningFill size={30} />
              </div>
              Instant Sell
            </button>
          )}
        </div>
      </div>
      <div className="mt-3 flex gap-1">
        <p className="font-bold text-red-600 text-xl">
          &#8377;{" "}
          {isNaN(
            lot *
              currentData.SEM_LOT_UNITS *
              (type === "PE" ? currentData.LTP : currentData.LTP)
          )
            ? 0
            : (
                lot *
                currentData.SEM_LOT_UNITS *
                (type === "PE" ? currentData.LTP : currentData.LTP)
              ).toFixed(2)}
        </p>
      </div>

      <div className="p-5 border border-pink-200 flex  bg-pink-50 mt-16"></div>
    </div>
  );
}

export default InstantOptionsCard;
