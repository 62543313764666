import IndexPriceDisplay from "./IndexPriceDisplay/IndexPriceDisplay";
import StockScreener from "./StockScreener/StockScreener";

function LandingPage() {
  return (
    <div
      style={{
        minHeight: "80vh",
      }}
    >
      <StockScreener />
      {/* <IndexPriceDisplay></IndexPriceDisplay> */}
    </div>
  );
}

export default LandingPage;
