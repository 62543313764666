import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import ClipLoader from "react-spinners/ClipLoader";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import ReactDOMServer from "react-dom/server";
import { FaSquare } from "react-icons/fa6";

function OIChart({ oiChartData, indexLtp, strikesLimit, showBarChart }) {
  const [graphData, setGraphData] = useState([
    ["strike_price", "OI_CE", "OI_PE"],
  ]);
  const [loading, setLoading] = useState(true);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [range, setRange] = useState([0, 0]);
  const [isSliding, setIsSliding] = useState(false);

  useEffect(() => {
    if (oiChartData.length > 0) {
      const strikePrices = oiChartData && oiChartData.map((d) => d[0]);
      const minStrikePrice = Math.min(...strikePrices);
      const maxStrikePrice = Math.max(...strikePrices);

      setMinPrice(minStrikePrice);
      setMaxPrice(maxStrikePrice);

      // Find the nearest strike price to the indexLtp
      const nearestIndex = strikePrices.reduce((prev, curr) =>
        Math.abs(curr - indexLtp) < Math.abs(prev - indexLtp) ? curr : prev
      );
      // Get the index of the nearest strike price
      const nearestIndexPosition = strikePrices.indexOf(nearestIndex);
      const lowerBound = Math.max(0, nearestIndexPosition - strikesLimit / 2);
      const upperBound = Math.min(
        strikePrices.length - 1,
        nearestIndexPosition + strikesLimit / 2
      );

      const defaultRange = [strikePrices[lowerBound], strikePrices[upperBound]];
      setRange(defaultRange);
    }
  }, [oiChartData, strikesLimit]);

  useEffect(() => {
    setLoading(true);
    const strikePrices = oiChartData && oiChartData.map((d) => d[0]);
    const nearestIndex =
      strikePrices &&
      strikePrices.reduce((prev, curr) =>
        Math.abs(curr - indexLtp) < Math.abs(prev - indexLtp) ? curr : prev
      );
    const filteredData =
      oiChartData &&
      oiChartData.filter((d) => d[0] >= range[0] && d[0] <= range[1]);

    const dataWithStyle =
      filteredData &&
      filteredData.map((d) => {
        const annotation =
          d[0] === nearestIndex ? `Spot Price: ${indexLtp}` : null;
        const tooltip = `
                        <div style="font-size: 15px; color: black; padding: 10px; width: 150px; background-color: #fff; border: none; border-radius: 10px;">
                          Strike Price: <strong>${d[0]}</strong><br>
                          <hr>
                          <div style="display: flex; align-items: center; margin-bottom: 5px;">
                            ${ReactDOMServer.renderToString(
                              <FaSquare color="red" />
                            )}
                            <span style="margin-left: 8px;">PE: <strong>${
                              d[2]
                            }</strong></span>
                          </div>
                          <div style="display: flex; align-items: center;">
                            ${ReactDOMServer.renderToString(
                              <FaSquare color="green" />
                            )}
                            <span style="margin-left: 8px;">CE: <strong>${
                              d[1]
                            }</strong></span>
                          </div>
                           <div style="display: flex; align-items: center;">
                           <span>Delta: <strong>${d[2] - d[1]}</strong></span>
                          </div>  
                        </div>
                       `;
        return [d[0], annotation, d[1], tooltip, d[2], tooltip];
      });

    setGraphData([
      [
        "strike_price",
        {
          role: "annotation",
          type: "string",
        },
        "OI_CE",
        { role: "tooltip", type: "string", p: { html: true } },
        "OI_PE",
        { role: "tooltip", type: "string", p: { html: true } },
      ],
      ...dataWithStyle,
    ]);
    if (graphData.length > 1) {
      setLoading(false);
    }
  }, [range, oiChartData, indexLtp]);

  const options = {
    hAxis: {
      title: "Strike Price",
      gridlines: { color: "none" },
    },
    vAxis: {
      gridlines: { color: "none" },
      format: "short",
      logscale: true,
      viewWindow: {
        min: 0,
      },
    },
    orientation: "horizontal",
    annotations: {
      alwaysOutside: true,
      position: "top",
      style: "line",
      textStyle: {
        fontSize: 15,
        color: "#000",
      },

      stem: {
        color: "#000",
        line: "dotted",
      },
    },
    tooltip: {
      isHtml: true,
    },
    legend: { position: "bottom" },
    colors: ["#03C03C", "#FF033E"],
    curveType: "function",
    chartArea: { width: "90%", height: "80%", top: 10 },
  };

  const handleSliderChange = (values) => {
    setRange(values);
    setIsSliding(true);
  };

  return (
    <div className="h-[500px]">
      {loading ? (
        <div className="flex justify-center min-h-[300px] items-center">
          <ClipLoader
            color="blue"
            loading={loading}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          {showBarChart ? (
            <Chart
              chartType="BarChart"
              width="100%"
              height="100%"
              data={graphData}
              options={options}
            />
          ) : (
            <Chart
              chartType="LineChart"
              width="100%"
              height="100%"
              data={graphData}
              options={options}
            />
          )}
          <div className="mt-1 w-3/4 m-auto">
            <RangeSlider
              min={minPrice}
              max={maxPrice}
              value={range}
              onInput={handleSliderChange}
              step={5}
              onPointerUp={() => setIsSliding(false)}
            />
            <div className="flex justify-between text-sm mt-2 font-bold">
              <span>{isSliding ? range[0] : minPrice}</span>
              <span>{isSliding ? range[1] : maxPrice}</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default OIChart;
