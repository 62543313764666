import { NavLink } from "react-router-dom";
import logo from "../../Assets/logo.png";
import { useSelector } from "react-redux";
import { useState } from "react";

function Header() {
  const isHideHeader = useSelector((state) => state.tradeData.isHideHeader);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  return (
    <header
      className={`bg-white dark:bg-gray-900 theme-main-menu transition-opacity duration-500 ease-in-out ${
        isHideHeader ? "opacity-0" : "opacity-100"
      } ${isHideHeader ? "max-h-0 overflow-hidden" : ""} no-print`}
    >
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4 md:py-6">
          {/* Logo */}
          <div className="flex items-center">
            <NavLink to="/">
              <img className="h-10" src={logo} alt="Logo" />
            </NavLink>
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={toggleMobileMenu}
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-dropdown"
            aria-expanded={isMobileMenuOpen}
          >
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>

          {/* Navigation Menu for Desktop */}
          <nav className="hidden md:flex flex-grow justify-center">
            <ul className="flex space-x-8">
              <li>
                <NavLink
                  to="/oi-analysis"
                  className={({ isActive }) =>
                    isActive
                      ? " text-green-500  py-2 px-4 block hover:text-green-300"
                      : "text-gray-400 hover:text-green-300 py-2 px-4 block"
                  }
                >
                  Index Option Analysis
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/option-chain"
                  className={({ isActive }) =>
                    isActive
                      ? " text-green-500  py-2 px-4 block hover:text-green-300"
                      : "text-gray-400 hover:text-green-300 py-2 px-4 block"
                  }
                >
                  Option Chain
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/stock-screener"
                  className={({ isActive }) =>
                    isActive
                      ? " text-green-500  py-2 px-4 block hover:text-green-300"
                      : "text-gray-400 hover:text-green-300 py-2 px-4 block"
                  }
                >
                  Stock Screener
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/oi-chart"
                  className={({ isActive }) =>
                    isActive
                      ? " text-green-500  py-2 px-4 block hover:text-green-300"
                      : "text-gray-400 hover:text-green-300 py-2 px-4 block"
                  }
                >
                  OI Chart
                </NavLink>
              </li>
            </ul>
          </nav>

          {/* Login Button */}
          <div className="flex items-center">
            <NavLink
              to="/login"
              className="px-4 py-1 rounded-full hover:bg-teal-300 text-gray-400 hover:text-white"
            >
              Login <i className="flaticon-right-thin"></i>
            </NavLink>
          </div>
        </div>

        {/* Dropdown Menu for Mobile */}
        <div
          className={`md:hidden ${isMobileMenuOpen ? "block" : "hidden"}`}
          id="navbar-dropdown"
        >
          <ul className="flex flex-col mt-4 space-y-2">
            <li>
              <NavLink
                to="/oi-analysis"
                className={({ isActive }) =>
                  isActive
                    ? " text-green-500  py-2 px-4 block hover:text-green-300"
                    : "text-gray-400 hover:text-green-300 py-2 px-4 block"
                }
                onClick={toggleMobileMenu}
              >
                Index Option Analysis
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/option-chain"
                className={({ isActive }) =>
                  isActive
                    ? " text-green-500  py-2 px-4 block hover:text-green-300"
                    : "text-gray-400 hover:text-green-300 py-2 px-4 block"
                }
                onClick={toggleMobileMenu}
              >
                Option Chain
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/stock-screener"
                className={({ isActive }) =>
                  isActive
                    ? " text-green-500  py-2 px-4 block hover:text-green-300"
                    : "text-gray-400 hover:text-green-300 py-2 px-4 block"
                }
                onClick={toggleMobileMenu}
              >
                Stock Screener
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/oi-chart"
                className={({ isActive }) =>
                  isActive
                    ? " text-green-500  py-2 px-4 block hover:text-green-300"
                    : "text-gray-400 hover:text-green-300 py-2 px-4 block"
                }
                onClick={toggleMobileMenu}
              >
                OI Chart
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
