import { useEffect, useState } from "react";
import ApiService from "../../../../Container/Services/ApiService";
import RangeSlider from "react-range-slider-input";
import { Grid } from "react-loader-spinner";
import "./StockScreener.css";
import moment from "moment-timezone";

function StockScreener() {
  const [loader, setLoader] = useState(true);
  const [category, setCategory] = useState("NIFTY 50");
  const [stockData, setStockData] = useState(null);
  const [confRange, setConfRange] = useState([25, 100]);
  const [dateFilter, setDateFilter] = useState("Latest Date");
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    fetchData();
  }, [category]);

  const fetchData = () => {
    setStockData(null);
    setLoader(true);

    ApiService.fetchData(`/data/fetch/sector-indices`).then((data) => {
      if (data.success) {
        setCategories(data.data);
      }
    });

    ApiService.fetchData(`/data/fetch/reco/sector-indices/${category}`).then(
      (data) => {
        if (data.success) {
          setStockData(data.data);
          setLoader(false);
        }
      }
    );
  };

  const onFilterChange = (ev) => {
    setCategory(ev.target.value);
  };

  const filteredStockList = stockData
    ? stockData.filter((stockInfo) => {
        return (
          !(
            parseInt(stockInfo.RECOMMEND_ALL) < confRange[0] ||
            parseInt(stockInfo.RECOMMEND_ALL) > confRange[1]
          ) &&
          (dateFilter == "Latest Date"
            ? moment(stockInfo.date)
                .tz("Asia/Kolkata")
                .startOf("day")
                .isSame(
                  moment(stockData[0].date).tz("Asia/Kolkata").startOf("day")
                )
            : true)
        );
      })
    : [];

  const stockList = (
    <div className="p-6 m-6 flex flex-wrap gap-[10px] justify-start ">
      {stockData
        ? filteredStockList
            .sort((a, b) => {
              // Sort by date in descending order
              const dateA = new Date(a.date);
              const dateB = new Date(b.date);

              if (dateB - dateA !== 0) {
                return dateB - dateA; // If dates are not equal, sort by date first
              }

              // If dates are equal, sort by RECOMMEND_ALL in descending order
              const recommendA = parseFloat(a.RECOMMEND_ALL);
              const recommendB = parseFloat(b.RECOMMEND_ALL);

              return recommendB - recommendA; // Sort by RECOMMEND_ALL in descending order
            })
            .map((stockInfo) => {
              return (
                <RecommendedStock
                  key={stockInfo.tvTrading}
                  stockInfo={stockInfo}
                  defaultValue={confRange}
                  onInput={setConfRange}
                />
              );
            })
        : ""}
    </div>
  );

  return (
    <div className="mt-[150px] flex flex-col">
      <h1 className="text-green-500 text-center text-2xl">
        Stock Trends from Algorithm V1.0
      </h1>
      <div className="m-6 p-6 border rounded-lg flex flex-col sm:flex-col md:flex-row sm:p-2 sm:m-2 no-print">
        <div className="sm:w-full md:w-1/3 flex flex-col px-2">
          <label>Filter by Indice/Sector</label>

          <select
            onChange={onFilterChange}
            defaultValue={category}
            value={category}
            className="p-2"
          >
            <option>All Stocks</option>
            {Object.keys(categories)
              .sort()
              .map((key) => {
                let category = categories[key];
                return (
                  <optgroup label={key}>
                    {category.map((cat) => {
                      return <option>{cat.name}</option>;
                    })}
                  </optgroup>
                );
              })}
          </select>
        </div>
        <div className="sm:w-full md:w-1/3 flex flex-col px-2">
          <div className="flex flex-row">
            <label>Algorithm Confidence</label>
          </div>
          <div className=" flex flex-row">
            <span className="flex self-start">{confRange[0]}</span>
            <RangeSlider
              min={0}
              max={100}
              value={confRange}
              onInput={(value) => {
                setConfRange(value);
              }}
            />
            <span className="flex self-end">{confRange[1]}</span>
          </div>
        </div>
        <div className="sm:w-full md:w-1/3 flex flex-col px-2">
          <label>Filter by Date</label>
          <select
            onChange={(ev) => {
              setDateFilter(ev.target.value);
            }}
            defaultValue={dateFilter}
            value={dateFilter}
            className="p-2"
          >
            <option>Latest Date</option>
            <option>All</option>
          </select>
        </div>
      </div>
      <div className="px-6 mx-6 flex no-print">
        <p>
          {stockData ? (
            <>
              {"Displaying "} <strong>{filteredStockList.length} </strong>Items
            </>
          ) : (
            ""
          )}
        </p>
      </div>
      {loader ? (
        <div className=" flex flex-col justify-center items-center">
          <Grid
            visible={true}
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass="grid-wrapper"
          />
        </div>
      ) : (
        stockList
      )}
    </div>
  );
}

function RecommendedStock({ stockInfo }) {
  return (
    <div
      className={
        (stockInfo.type == "Bearish"
          ? " border-[2px] border-solid border-red-300 bg-red-50"
          : " border-[2px] border-solid  border-green-300 bg-green-50") +
        "  px-2  rounded-lg p-4  sm:w-full md:w-calc-25  "
      }
    >
      <div className="text-gray-700  font-bold">{stockInfo.stock.name}</div>
      <div className="flex flex-row justify-between">
        <div className="sm:text-sm md:text-lg mt-1">
          Entry: ₹{stockInfo.entryPrice}
          <span className="sm:text-sm md:text-lg">
            <a
              href={`https://in.tradingview.com/chart/17z1sbq6/?symbol=NSE%3A${stockInfo.tvTrading}`}
              target="_blank"
            >
              Watch on Trading View
            </a>
          </span>
        </div>
        <div className="justify-end">
          <CalendarStamp date={stockInfo.date} />
        </div>
      </div>

      <div className={stockInfo.displayType + " mt-1"}>{stockInfo.type}</div>
      <div>Confidence Score: {stockInfo.RECOMMEND_ALL}</div>
    </div>
  );
}

const CalendarStamp = ({ date }) => {
  // Format the date using moment.js
  const day = moment(date).format("DD");
  const month = moment(date).format("MMM").toUpperCase();
  const year = moment(date).format("YYYY");

  return (
    <div className="calendar-stamp">
      <div className="day">{day}</div>
      <div className="month-year">
        <span className="month">{month}</span>
      </div>
    </div>
  );
};

export default StockScreener;
