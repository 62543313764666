import React, { useEffect, useState } from "react";
import {
  FaArrowUp,
  FaArrowDown,
  FaCircleInfo,
  FaBriefcase,
  FaBolt,
  FaArrowsSpin,
  FaGear,
  FaBan,
  FaBasketShopping,
} from "react-icons/fa6";
import ApiService from "../../Services/ApiService";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import getSocket from "../../Services/API/Socket";

export default function Controls() {
  const [positions, setPositions] = useState([]);
  const [orders, setOrders] = useState([]);
  const [indexes, setIndexes] = useState([]);
  const [fund, setFund] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const socket = getSocket();

  const refreshPositions = () => {
    ApiService.fetchData("/dhan/positions-and-orders").then((response) => {
      if (response.status) {
        setOrders(response.data.orders);
        setPositions(response.data.positions);
      }
    });
  };

  useEffect(() => {}, selectedIndex);
  useEffect(() => {
    ApiService.fetchData("/data/fetch/indexes").then((response) => {
      if (response.status) {
        setIndexes(response.data.indexes);
        console.log(response.data);
        response.data.indexes.map((index) => {
          console.log(selectedIndex);
          return index.SEM_TRADING_SYMBOL.startsWith("BANKNIFTY")
            ? setSelectedIndex(index)
            : null;
        });
      }
    });
    ApiService.fetchData("/dhan/fund-limit").then((response) => {
      if (response.status) {
        setFund(response.data);
      }
    });
    refreshPositions();
    setInterval(() => {
      refreshPositions();
    }, 6000);
  }, []);

  return (
    <div className="flex flex-col m-2 font-sans">
      <h1 className="font-bold text-green-500 text-center text-2xl mb-2 items-center flex flex-row justify-center">
        <FaBolt />
        FLASH TRADER
      </h1>
      <IndexBlock fund={fund} selectedIndex={selectedIndex} />
      <Positions positions={positions} />

      <Orders orders={orders} />
      {/* <OIData /> */}
      <div></div>
    </div>
  );
}

function Orders({ orders }) {
  return (
    <div className="bg-white shadow-lg rounded-lg border border-gray-200 p-2 ">
      <div className=" flex flex-row justify-between  ">
        <h2 className="font-bold flex flex-row items-center">
          {" "}
          <FaBriefcase /> &nbsp;Pending Orders
        </h2>
        <small>
          <a href="#" className="text-blue-600 cursor-pointer">
            View Traded
          </a>
        </small>
      </div>

      <div className="bg-yellow-100 shadow-lg rounded-lg border border-gray-200 p-2 m-1 ">
        <h3 className="font-bold flex flex-row items-center p-2 text-sm ">
          <FaGear /> &nbsp; Modify All Orders
        </h3>
        <div className="flex flex-row justify-around overflow-hidden">
          <select className="mr-1 p-1 flex flex-1">
            <option>MARKET</option>
            <option>LIMIT</option>
            <option>STOPLOSS</option>
          </select>
          <input
            type="number"
            className="mr-1 p-1 flex flex-1 w-1/3"
            placeholder="Price"
          />
          <button class="bg-purple-900 text-white w-full font-bold py-2 px-4  rounded    flex-row items-center justify-center mr-1 flex flex-1">
            Update
          </button>
        </div>
      </div>
      {orders && orders.length > 0 ? (
        orders.map((order) => {
          if (order.orderStatus == "PENDING")
            return (
              <div
                className={`${
                  order.transactionType == "BUY"
                    ? "bg-green-900 text-white"
                    : "bg-red-800 text-white"
                } shadow-lg rounded-lg   p-2 m-1 transform transition-transform ${
                  order.orderStatus == "PENDING"
                    ? "border border-orange-500 border-4"
                    : ""
                } hover:scale-105 hover:shadow-2xl `}
              >
                <div className="flex flex-row justify-between">
                  <div>
                    {`${order.tradingSymbol.split("-")[0]} ${
                      order.drvStrikePrice
                    }
               ${moment(order.drvExpiryDate).format("DD MMM")} ${
                      order.drvOptionType
                    } ${order.transactionType}`}
                  </div>
                  <div>QTY : {`${order.quantity}`}</div>
                </div>
                <div className="flex flex-row justify-between">
                  <div>{`${order.orderType}`}</div>
                  <div>{`${order.price}`}</div>
                  <div>{`LTP`}</div>
                </div>
              </div>
            );
          else {
            return null;
          }
        })
      ) : (
        <div
          class="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <span class="block sm:inline">No Open orders.</span>
        </div>
      )}
    </div>
  );
}

function Positions({ positions }) {
  return (
    <div className="bg-white shadow-lg rounded-lg border border-gray-200 p-2  flex flex-col mt-2">
      <div className="flex flex-row justify-between">
        <h2 className="font-bold flex flex-row items-center">
          <FaBasketShopping />
          &nbsp;Positions Summary
        </h2>
        <small>
          <a href="#" className="text-blue-600 cursor-pointer">
            View Closed
          </a>
        </small>
      </div>
      <div className="flex flex-row justify-between text-sm">
        <div className="flex flex-1 flex-col items-center">
          <h4>Open P/L</h4>
          <div>
            {(() => {
              let profit = 0;
              positions.map((position) => {
                if (position.positionType != "CLOSED")
                  profit += position.realizedProfit + position.unrealizedProfit;
              });
              return (
                <div
                  className={profit >= 0 ? "text-green-600" : "text-red-600"}
                >
                  {parseFloat(profit).toFixed(2)}
                </div>
              );
            })()}
          </div>
        </div>
        <div className="flex flex-1 flex-col items-center">
          <h4>Closed P/L</h4>
          <div>
            {(() => {
              let profit = 0;
              positions.map((position) => {
                if (position.positionType == "CLOSED")
                  profit += position.realizedProfit;
              });
              return (
                <div
                  className={profit >= 0 ? "text-green-600" : "text-red-600"}
                >
                  {parseFloat(profit).toFixed(2)}
                </div>
              );
            })()}
          </div>
        </div>
        <div className="flex flex-1 flex-col items-center">
          <h4>Total P/L</h4>
          <div>
            {(() => {
              let profit = 0;
              positions.map((position) => {
                profit +=
                  position.positionType != "CLOSED"
                    ? position.realizedProfit + position.unrealizedProfit
                    : position.realizedProfit;
              });
              return (
                <div
                  className={profit >= 0 ? "text-green-600" : "text-red-600"}
                >
                  {parseFloat(profit).toFixed(2)}
                </div>
              );
            })()}
          </div>
        </div>
      </div>
      {positions && positions.length > 0 ? (
        positions.map((position, idx) => {
          return position.positionType != "CLOSED" ? (
            <OpenPosition position={position} />
          ) : null;
        })
      ) : (
        <div className="flex flex-row">
          <button
            class="bg-red-500 text-white font-bold py-2 px-4 mr-1 flex-1 rounded flex flex-row justify-center items-center"
            onClick={() => {
              window.alert("Feature in development");
            }}
          >
            ENTER LONG <FaArrowUp />
          </button>
          <button
            class="bg-green-500 text-white font-bold py-2 px-4 flex-1 rounded flex flex-row justify-center items-center"
            onClick={() => {
              window.alert("Feature in development");
            }}
          >
            ENTER SHORT <FaArrowDown />
          </button>
        </div>
      )}
    </div>
  );
}

function OpenPosition({ position }) {
  const [value, setValue] = useState(position.LTP || 0.0);

  useEffect(() => {
    const socket = getSocket();
    socket.on("data", (data) => {
      if (
        data.type == "Quote Data" &&
        data.LTP &&
        data.securityId &&
        data.securityId == position.securityId
      ) {
        setValue(data.LTP);
      }
    });
  }, [value, position]);

  let profit = parseFloat(position.realizedProfit);
  if (position.positionType != "CLOSED") {
    profit =
      position.daySellValue > 0
        ? position.daySellValue -
          position.dayBuyValue +
          (value - position.buyAvg) * position.netQty
        : (value - position.buyAvg) * position.netQty;
  }
  return (
    <div
      className={`bg-white shadow-lg rounded-lg border border-gray-200 p-2 m-1 transform transition-transform hover:scale-105 hover:shadow-2xl ${
        position.positionType != "CLOSED" ? "border-green-600 border-4" : ""
      }`}
    >
      <div className="flex flex-row justify-between">
        <div>
          {`${position.tradingSymbol.split("-")[0]} ${position.drvStrikePrice}
               ${moment(position.drvExpiryDate).format("DD MMM")} ${
            position.drvOptionType
          }`}
        </div>
        <div>QTY : {`${position.netQty}`}</div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-1 justify-start">
          Buy: {`${parseFloat(position.buyAvg).toFixed(2)}`}
        </div>
        <div className="flex flex-1 justify-center">
          LTP: {`${value || "0.00"}`}
        </div>
        <div
          className={`flex flex-1 justify-end font-bold ${
            profit >= 0 ? "text-green-800" : "text-red-800"
          }`}
        >{`${parseFloat(profit.toFixed(2))}`}</div>
      </div>
    </div>
  );
}

function IndexBlock({ fund, selectedIndex }) {
  const [indexValue, setIndexValue] = useState(
    selectedIndex && selectedIndex.LTP ? selectedIndex.LTP : null
  );
  useEffect(() => {
    const socket = getSocket();
    socket.on("data", (data) => {
      // if (data.type == "Quote Data") console.log(data);
      if (
        data.type == "Quote Data" &&
        data.LTP &&
        data.securityId &&
        selectedIndex &&
        data.securityId == selectedIndex.SEM_SMST_SECURITY_ID
      ) {
        setIndexValue(data.LTP);
      }
    });
  });
  if (!selectedIndex) return null;
  return (
    <div className="bg-white shadow-lg rounded-lg border border-gray-200 p-2 ">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <h2 className="font-semibold">BANKNIFTY</h2>
          <h3>
            {indexValue}(
            <span
              className={`${
                indexValue - selectedIndex.open >= 0
                  ? "text-green-500 font-semibold"
                  : "text-red-500 font-semibold"
              }`}
            >
              {indexValue - selectedIndex.open >= 0 ? "+" : ""}
              {parseFloat(indexValue - selectedIndex.open).toFixed(2)}
            </span>
            )
          </h3>
        </div>
        <div>
          {fund ? (
            <div className="flex flex-col">
              <div>Available Bal</div>
              <div> ₹{fund.availabelBalance} </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="flex flex-col m-1">
        <div className="flex flex-row justify-center items-center">
          <button class="bg-red-500 text-white w-full font-bold py-2 px-4 flex-1 rounded  flex flex-row items-center justify-center mr-1">
            <FaBan /> &nbsp; KILL ALL
          </button>
          <button class="bg-blue-500 text-white w-full font-bold py-2 px-4 mt-1 flex-1 rounded mr-1 flex flex-row items-center justify-center">
            <FaArrowsSpin /> &nbsp; REVERSE
          </button>
        </div>
      </div>
    </div>
  );
}

function OIData({ positions }) {
  return (
    <div className="bg-white shadow-lg rounded-lg border border-gray-200 p-2  flex flex-col mt-2">
      <div className="flex flex-row justify-between">
        <h2 className="font-bold flex flex-row items-center">
          <FaBasketShopping />
          &nbsp;OI Summary
        </h2>
      </div>
      <div className="flex flex-row justify-between text-sm">
        <div className="flex flex-1 flex-col">
          <h4>5 Minute</h4>
          <div>+100</div>
        </div>
        <div className="flex flex-1 flex-col">
          <h4>3 Min</h4>
          <div>-100</div>
        </div>
        <div className="flex flex-1 flex-col">
          <h4>1 Min</h4>
          <div>-320</div>
        </div>
      </div>
    </div>
  );
}

function AllPositions({ positions }) {
  return <div>Hello</div>;
}
